import React, { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Link } from "react-router-dom";

import { hasOnScreenNavigation } from "util/utils";
import useView from "components/Hooks/useViewHook";

function CalendlyWidget({ prefill = {}, onMeetingSuccess = () => "", calendlyLink = "" }) {
  const [primaryColor, setPrimaryColor] = useState("");

  const { isMobile } = useView();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      onMeetingSuccess(e.data.payload);
    },
  });

  useEffect(() => {
    const themeColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--color-primitives-primary-600")
      .trim();
    setPrimaryColor(themeColor);
  }, []);

  if (!calendlyLink) {
    return (
      <div className="text-center para-body-m-regular p-4">
        It seems that your Account Manger does not setup calendly. Please reach out to your sales representative or{" "}
        <Link
          className="mail-to-link para-body-m-regular"
          to="#"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto:support@onedge.co";
          }}
        >
          support@onedge.co
        </Link>{" "}
        to get help.
      </div>
    );
  }
  return (
    <InlineWidget
      styles={{
        height: "90%",
        width: "90vw",
        minWidth: "320px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: isMobile && hasOnScreenNavigation() ? "50px" : "0px",
      }}
      url={calendlyLink}
      prefill={prefill}
      pageSettings={{
        hideLandingPageDetails: false,
        hideEventTypeDetails: false,
        primaryColor: primaryColor || "#8139D0",
        textColor: "#000000",
        backgroundColor: "#ffffff",
      }}
    />
  );
}

export default CalendlyWidget;
