import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import PageLoader from "components/common/PageLoader/index";
import { convertDate, getValue } from "components/Screens/CommonUtils/index";
import Button from "components/common/Button";

function PersonalInfo({ showEditPersonalInfoModal }) {
  const rowClassesForPersonalInfo = "flex justify-between w-100p mb-1";

  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const {
    dateOfBirth,
    country,
    gender,
    cnic_dni_cex: nationalID,
    allowedForPayoneer,
    maritalStatus,
    preferredName,
  } = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails)?.payload || {};

  return (
    <>
      {loadingGetEmployeeDetails && <PageLoader />}
      <div className={rowClassesForPersonalInfo.replace("mb-1", "mb-15")}>
        <div className="flex items-center gap-05">
          <PersonOutlineIcon className="color-icon-primary admin-overview-heading-icon" alt="Profile" />
          <div className="heading-h4-semibold">Personal</div>
        </div>

        <Button
          label="Edit"
          size="small"
          onClick={showEditPersonalInfoModal}
          variant="outlined"
          startIcon={<EditOutlinedIcon />}
        />
      </div>

      <Grid item container direction="column" rowSpacing={1.5} xs={12}>
        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Preferred Name</div>
          <div className="para-body-m-semibold">{getValue(preferredName)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Country</div>
          <div className="para-body-m-semibold">{getValue(country)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">National ID</div>
          <div className="para-body-m-semibold">{getValue(nationalID)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Date of Birth</div>
          <div className="para-body-m-semibold">{convertDate(dateOfBirth)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Gender</div>
          <div className="para-body-m-semibold">{getValue(gender)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Marital Status</div>
          <div className="para-body-m-semibold">{getValue(maritalStatus)}</div>
        </div>

        {country === "Pakistan" && (
          <div className={rowClassesForPersonalInfo.replace("mb-1", "")}>
            <div className="heading-h5-regular">Edge Wallet</div>
            <div className="para-body-m-semibold">{getValue(allowedForPayoneer ? "Enabled" : "Disabled")}</div>
          </div>
        )}
      </Grid>
    </>
  );
}

export default PersonalInfo;
