import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { Box, Switch } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";

import PageLoader from "components/common/PageLoader";
import TableComponent from "components/common/TableComponent";
import { AlertType } from "components/common/Snackbar";
import DocumentModal from "../DocumentModal/DocumentModal";
import DocumentRenameModal from "../DocumentRenameModal/DocumentRenameModal";
import DocumentDeleteModal from "../DocumentDeleteModal/DocumentDeleteModal";
import DocumentMoveModal from "../DocumentMoveModal/DocumentMoveModal";
import NoDocumentScreen from "../NoDocumentScreen/NoDocumentScreen";
import DocumentTopBar from "../DocumentTopBar/DocumentTopBar";

import {
  downloadEmployeeDocument,
  getEmployeeDocumentList,
  updateEmployeeDocumentInfo,
  saveEmployeeDocumentInfo,
} from "services/BambooHrTabs/documents";

import { showSnackbar } from "store/actions/Utility";
import { DropdownData } from "store/actions/DropdownData";

import { getDocumentPermissions, getFileSizeToDisplay } from "util/utils";

import "../Document.scss";

function DocumentList({
  noPadding,
  employeeId,
  loggedInUserType,
  documentType,
  refreshEmployeeList,
  openModalState,
  setOpenModal,
  setDocumentType,
  onBackClicked,
}) {
  const dispatch = useDispatch();

  const documentTypes = useSelector((state) => state.DropdownData?.dropdownData?.payload?.documentTypes || []);
  const documentPermissions = documentTypes?.find((type) => type.id === documentType)?.actions || {};

  const [tableData, setTableData] = useState([]);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [refreshList, setRefreshList] = useState(0);
  const [modalType, setModalType] = useState("add");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [localDocumentStates, setLocalDocumentStates] = useState({});

  const { count: documentsCount = 0, documents = [] } = tableData || {};

  useEffect(() => {
    if (openModalState) {
      setShowDocumentModal(true);
      setModalType("add");
      setEditRowData(null);
      setOpenModal(false);
    }
  }, [openModalState, setOpenModal]);

  const employeeDocumentList = useCallback(async () => {
    try {
      setIsLoading(true);
      const documentList = await getEmployeeDocumentList(employeeId, limit, page, orderBy, {
        documentType: [documentType],
      });
      setTableData(documentList);
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, limit, orderBy, page, employeeId, documentType]);

  useEffect(() => {
    employeeDocumentList();
  }, [refreshList, employeeDocumentList]);

  useEffect(() => {
    if (documentTypes.length === 0) {
      dispatch(DropdownData());
    }
  }, [dispatch, documentTypes.length]);

  async function downloadDocument(doc) {
    try {
      document.body.style.cursor = "wait";

      dispatch(
        showSnackbar({
          type: AlertType.info,
          message: "",
          snackbarMessageContent: (
            <div className="para-body-m-regular">
              Downloading <span className="para-body-m-semibold">{doc?.documentName}</span>...
            </div>
          ),
          prefixIcon: <FileDownloadOutlinedIcon className="snackbar-icon" />,
          dispatch,
        })
      );

      const res = await downloadEmployeeDocument(employeeId, doc?.documentId);
      const { fileUrl } = res;
      if (!fileUrl) {
        throw new Error("File URL not found");
      }

      const response = await fetch(res.fileUrl);
      const blob = await response.blob();
      const link = window.document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", doc?.documentName);
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      document.body.style.cursor = "default";
    }
  }

  const handleUpdate = async ({ fileName, shareWithEmployee, shareWithCustomer }) => {
    const payload = {
      documentName: fileName,
      documentType,
      shareWithEmployee,
      shareWithCustomer,
    };
    try {
      setIsLoading(true);
      await updateEmployeeDocumentInfo(employeeId, editRowData?.documentId, payload);
      setRefreshList(refreshList + 1);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document updated successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async ({ fileName, updatedFileName, selectedFile, shareWithEmployee, shareWithCustomer }) => {
    setIsLoading(true);
    const payload = {
      fileName: updatedFileName,
      documentName: fileName,
      fileType: selectedFile.type,
      size: selectedFile.size,
      documentType,
      shareWithEmployee,
      shareWithCustomer,
    };
    try {
      await saveEmployeeDocumentInfo(employeeId, payload);

      setRefreshList(refreshList + 1);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document added successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  const getFileIcon = (fileType) => {
    if (fileType === "application/pdf") {
      return <PictureAsPdfOutlinedIcon color="primary" sx={{ marginRight: 1.5 }} />;
    }
    if (fileType === "image/png" || fileType === "image/jpeg") {
      return <FilterOutlinedIcon color="primary" sx={{ marginRight: 1.5 }} />;
    }
    return <FileDownloadOutlinedIcon color="primary" sx={{ marginRight: 1.5 }} />;
  };

  const getMoreActions = () => {
    if (loggedInUserType !== "edge") {
      return null;
    }
    if (documentType === "Signed Documents") {
      return [{ label: "Edit" }];
    }
    return [{ label: "Edit" }, { label: "Delete" }];
  };

  const handleToggleShare = async (documentId, field) => {
    try {
      setLocalDocumentStates((prev) => ({
        ...prev,
        [documentId]: {
          ...prev[documentId],
          [field]: !prev[documentId][field],
        },
      }));

      await updateEmployeeDocumentInfo(employeeId, documentId, {
        [field]: !localDocumentStates[documentId][field],
      });
    } catch (error) {
      setLocalDocumentStates((prev) => ({
        ...prev,
        [documentId]: {
          ...prev[documentId],
          [field]: !prev[documentId][field],
        },
      }));
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    }
  };

  const getColumnsForNonAdmin = () => {
    const shouldIncludeColumns = !["customer", "employee"].includes(loggedInUserType);

    return [
      {
        id: "documentName",
        label: "Name",
        minWidth: 200,
        sortable: true,
        render: (value, row) => (
          <Box display="flex" alignItems="center">
            {getFileIcon(row.type)}
            <p className="para-body-m-regular">{value}</p>
          </Box>
        ),
      },
      ...(shouldIncludeColumns
        ? [
            {
              id: "type",
              label: "Type",
              minWidth: 100,
              render: (value) => {
                if (value === "application/pdf") return "PDF";
                if (value === "image/png") return "PNG";
                if (value === "image/jpeg") return "JPEG";
                return value;
              },
            },
            {
              id: "size",
              label: "Size",
              minWidth: 100,
              render: (value) => getFileSizeToDisplay(value),
            },
          ]
        : []),
      {
        id: "createdAt",
        label: "Added On",
        minWidth: 150,
        render: (value) => format(new Date(value), "dd MMM, yyyy"),
      },
      {
        id: "action",
        type: "action",
        actions: [{ icon: <FileDownloadOutlinedIcon />, label: "Download" }],
        moreActions: getMoreActions(loggedInUserType, documentType),
        minWidth: 100,
        align: "right",
      },
    ];
  };

  const getColumns = () => {
    return [
      {
        id: "documentName",
        label: "Name",
        sortable: false,
        minWidth: 340,
        render: (value, row) => (
          <Box display="flex" alignItems="center">
            {getFileIcon(row.type)}
            <p className="para-body-m-regular">{value}</p>
          </Box>
        ),
      },
      {
        id: "createdAt",
        sortable: true,
        label: "Added On",
        minWidth: 130,
        render: (value) => format(new Date(value), "dd MMM, yyyy"),
      },
      ...(getDocumentPermissions(documentPermissions?.shareWithEmployee)
        ? [
            {
              id: "shareWithEmployeeCol",
              label: "Shared with Employee",
              sortable: false,
              minWidth: 100,
              render: (_, { documentId }) => (
                <Switch
                  checked={localDocumentStates[documentId]?.shareWithEmployee || false}
                  onChange={() => handleToggleShare(documentId, "shareWithEmployee")}
                />
              ),
            },
          ]
        : []),

      ...(getDocumentPermissions(documentPermissions?.shareWithCustomer)
        ? [
            {
              id: "shareWithCustomerCol",
              label: "Shared with Customer",
              sortable: false,
              minWidth: 100,
              render: (_, { documentId }) => (
                <Switch
                  checked={localDocumentStates[documentId]?.shareWithCustomer || false}
                  onChange={() => handleToggleShare(documentId, "shareWithCustomer")}
                />
              ),
            },
          ]
        : []),

      {
        id: "action",
        type: "action",
        sortable: false,
        moreActions: (() => {
          if (loggedInUserType !== "edge") return null;

          return [
            ...(getDocumentPermissions(documentPermissions?.rename) ? [{ label: "Rename", variant: "regular" }] : []),
            ...(getDocumentPermissions(documentPermissions?.download)
              ? [{ label: "Download", variant: "regular" }]
              : []),
            ...(getDocumentPermissions(documentPermissions?.move)
              ? [{ label: "Move to Folder", variant: "regular" }]
              : []),
            ...(getDocumentPermissions(documentPermissions?.delete) ? [{ label: "divider" }] : []),
            ...(getDocumentPermissions(documentPermissions?.delete) ? [{ label: "Delete", variant: "error" }] : []),
          ];
        })(),
        minWidth: 100,
        align: "right",
      },
    ];
  };

  const handleAction = (action, row) => {
    switch (action.label) {
      case "Download":
        downloadDocument(row);
        break;
      case "Edit":
        setEditRowData(row);
        setShowDocumentModal(true);
        setModalType("edit");
        break;
      case "Delete":
        setEditRowData(row);
        setShowDeleteModal(true);
        break;
      case "Rename":
        setEditRowData(row);
        setShowRenameModal(true);
        break;
      case "Move to Folder":
        setEditRowData(row);
        setShowMoveModal(true);
        break;
      default:
        break;
    }
  };

  const handleSort = (property, direction) => {
    const fieldMap = {
      documentName: "Document_Name",
      type: "Document_Extension",
      size: "Size",
      createdAt: "createdAt",
    };
    if (fieldMap[property]) {
      setOrderBy({ field: fieldMap[property], direction });
    }
  };

  useEffect(() => {
    const initialStates = {};
    documents.forEach((doc) => {
      initialStates[doc.documentId] = {
        shareWithEmployee: doc.shareWithEmployee,
        shareWithCustomer: doc.shareWithCustomer,
      };
    });
    setLocalDocumentStates(initialStates);
  }, [documents]);

  return (
    <div className="w-full">
      <div className={`${noPadding ? "ml-05" : "mx-2"}`}>
        <DocumentTopBar
          onAddButtonClick={() => {
            setShowDocumentModal(true);
            setEditRowData(null);
            setModalType("add");
          }}
          onBackClicked={onBackClicked}
          folderName={documentType}
          showAddButton={
            !!documentsCount && loggedInUserType == "edge" && getDocumentPermissions(documentPermissions?.upload)
          }
        />
      </div>
      <div>
        {isLoading && <PageLoader />}

        {documentsCount > 0 && (
          <div className="mx-2">
            <TableComponent
              columns={!["customer", "employee"].includes(loggedInUserType) ? getColumns() : getColumnsForNonAdmin()}
              data={documents}
              onSort={handleSort}
              onPageChange={(newPage) => setPage(newPage)}
              onRowsPerPageChange={(newRowsPerPage) => setLimit(newRowsPerPage)}
              totalCount={documentsCount}
              tableProps={{ stickyHeader: true }}
              onAction={handleAction}
              tableHeadSmall
              tableCellSmall
            />
          </div>
        )}

        {!isLoading && documentsCount === 0 && (
          <NoDocumentScreen
            onAddDocumentButtonClicked={() => {
              setShowDocumentModal(true);
              setEditRowData(null);
              setModalType("add");
            }}
            showAddButton={getDocumentPermissions(documentPermissions?.upload)}
            documentType={documentType}
            loggedInUserType={loggedInUserType}
            refreshEmployeeList={refreshEmployeeList}
          />
        )}
        <DocumentModal
          type={modalType}
          open={showDocumentModal}
          onClose={() => {
            setShowDocumentModal(false);
          }}
          onSave={modalType === "edit" ? handleUpdate : handleSave}
          modalData={editRowData}
          isLoading={isLoading}
          documentType={documentType}
          fetchEmployeeDocumentList={employeeDocumentList}
        />
        <DocumentDeleteModal
          employeeId={employeeId}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          document={editRowData}
          onPostDelete={() => {
            setRefreshList(refreshList + 1);
            setShowDeleteModal(false);
          }}
        />
        <DocumentMoveModal
          employeeId={employeeId}
          open={showMoveModal}
          documentType={documentType}
          onClose={() => setShowMoveModal(false)}
          tableData={documents}
          onDocumentTypeChange={setDocumentType}
          document={editRowData}
          onDocumentMove={() => {
            setRefreshList(refreshList + 1);
            setShowMoveModal(false);
          }}
        />
        <DocumentRenameModal
          employeeId={employeeId}
          open={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          document={editRowData}
          onDocumentRename={() => {
            setRefreshList(refreshList + 1);
            setShowRenameModal(false);
          }}
        />
      </div>
    </div>
  );
}

export default DocumentList;
