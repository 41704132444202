import { Modal, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiModal-backdrop": {
      backgroundColor: "#13159a33",
    },
  },
  modalContent: {
    backgroundColor: theme.palette?.background.paper,
    boxShadow: theme.shadows[5],
    padding: "30px 24px",
    maxWidth: 530,
    width: "100%",
    borderRadius: "12px",
  },
}));

const CustomModal = ({ open, children, width, sx }) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modalContainer}>
      <Box className={classes.modalContent} sx={{ width: width, ...sx }}>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
