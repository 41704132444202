import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Stack, Avatar } from "@mui/material";

import NewSidebar from "components/main/sidebar";
import PageLoader from "components/common/PageLoader";
import AppHeader from "components/main/navbar.component";
import AppHeaderNew from "components/main/newNavbar";
import AppSideBar from "components/main/sidebar.component";

import CrossButtonIcon from "assets/svgs/CrossButtonIcon";
import BubbleTipIcon from "assets/svgs/BubbleTipIcon";
import avatarImage from "assets/images/fallback-image.svg";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import { getProfileImage, isEmptyObject, convertDate } from "components/Screens/CommonUtils";
import { checkIfPadding24Needed, getBackgroundColor, shouldAddCustomHeightOnCurrentPage } from "util/utils";
import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";

import { usePermissions } from "components/Hook";
import { useMenuItems, useStickyItems } from "components/main/sidebar/utils/AppRoutes";

import { GetNoticeBoard } from "store/actions/DashBoard/GetNoticeBoard";

import { USER_TYPES } from "constants";

import "./main.component.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  minHeight: "46px !important",
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();

  const { sideBarRevamp, inAppNotifications } = useFlags();

  const [open, setOpen] = useState(true);
  const [openBubble, setOpenBubble] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const [imageSrc, setImageSrc] = useState(avatarImage);
  const [closeSideBarFromMenuIcon, setCloseSideBarFromMenuIcon] = useState(false);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const { loggedInUser, loggedInUserType } = usePermissions();
  const { customerOnBoradingDate, userId, name, email } = loggedInUser;
  const { companyName, customerId, pictureVersion, customerPictureVersion, startDate } = userAdditionalInfo;

  const getMenuItems = useMenuItems();
  const getStickyItems = useStickyItems();

  const getUserInfo = useCallback(() => {
    if (!userAdditionalInfo) return {};

    let startDateAsPayload;
    if (loggedInUserType === "customer") {
      startDateAsPayload = customerOnBoradingDate;
    } else if (loggedInUserType === "edge") {
      startDateAsPayload = startDate;
    } else {
      startDateAsPayload = startDate;
    }

    const isBenchedEmployee = loggedInUserType === "employee" && !customerId;
    const displayCompanyName = isBenchedEmployee ? "No company assigned" : companyName;

    const avatarUrl =
      loggedInUserType === "edge"
        ? `${baseUrl}/user/${userId}/logo?id=${pictureVersion}`
        : `${baseUrl}/customer/${customerId}/logo?id=${customerPictureVersion}`;

    return {
      name,
      companyName: displayCompanyName,
      email,
      startDate: convertDate(startDateAsPayload),
      isBenchedEmployee,
      avatar: isBenchedEmployee ? (
        <Avatar
          className="sidebar-user-avatar-img"
          sx={{
            backgroundColor: "var(--color-primitives-neutral-300) !important",
          }}
        >
          <CorporateFareOutlinedIcon />
        </Avatar>
      ) : (
        <Avatar
          alt={displayCompanyName || name}
          imgProps={{ crossOrigin: "anonymous" }}
          src={avatarUrl}
          className="sidebar-user-avatar-img"
          sx={{
            backgroundColor: "var(--color-primitives-primary-400) !important",
          }}
        />
      ),
    };
  }, [loggedInUser, userAdditionalInfo, loggedInUserType, customerId]);

  const handleBubble = () => {
    setOpenBubble(!openBubble);
  };

  const copyToClipboard = (CopiedText) => {
    navigator.clipboard.writeText(CopiedText);
  };

  useEffect(() => {
    if (userAdditionalInfo?.accountManager?.picturePath) {
      getProfileImage(
        userAdditionalInfo?.accountManager?.userId,
        userAdditionalInfo?.accountManager?.pictureVersion,
        "user"
      ).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    if (isEmptyObject(loggedInUser) && loggedInUserType !== "employee") {
      const req = {
        edge: "customers/noticeboard",
        customer: `customer/${loggedInUser?.customerId}/noticeboard`,
      };

      dispatch(
        GetNoticeBoard({
          request: req[loggedInUserType],
        })
      );
    }
  }, [loggedInUser, loggedInUserType]);

  useEffect(() => {
    if (window.innerWidth < 1280) setOpen(false);
  }, []);

  useEffect(() => {
    if (location?.pathname === "/main/community") setOpen(false);
    if (window.innerWidth > 1280 && location?.pathname !== "/main/community" && open == false)
      if (closeSideBarFromMenuIcon !== true) setOpen(true);
      else setOpen(false);
  }, [location]);

  return (
    <>
      {loadingAdditionalInfo && <PageLoader />}
      <div style={{ display: "flex", overflowX: "hidden", width: "100%" }} className="disable-scrollbars">
        <CssBaseline />

        {inAppNotifications && loggedInUserType !== USER_TYPES.EDGE ? (
          <AppHeaderNew
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setOpen={setOpen}
            userDetails={loggedInUser}
            userAdditionalInfo={userAdditionalInfo}
            setCloseSideBarFromMenuIcon={setCloseSideBarFromMenuIcon}
          />
        ) : (
          <AppHeader
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setOpen={setOpen}
            userDetails={loggedInUser}
            userAdditionalInfo={userAdditionalInfo}
            setCloseSideBarFromMenuIcon={setCloseSideBarFromMenuIcon}
          />
        )}

        {sideBarRevamp ? (
          <NewSidebar
            open={open}
            menuItems={getMenuItems()}
            logo={<EdgeLogo size="md" state="light" />}
            smallLogo={<EdgeLogo size="md" icon state="light" />}
            userInfo={getUserInfo()}
            stickyItems={getStickyItems()}
          />
        ) : (
          <AppSideBar
            open={open}
            setOpen={setOpen}
            userDetails={loggedInUser}
            userAdditionalInfo={userAdditionalInfo}
          />
        )}

        <Box
          component="main"
          className="main-box"
          sx={{
            flexGrow: 1,
            p: loggedInUserType === "edge" && checkIfPadding24Needed() ? "24px !important" : "0px !important",
            backgroundColor: getBackgroundColor(),
          }}
        >
          {location?.pathname !== "/main/community" ? <DrawerHeader /> : null}
          {loggedInUserType === "edge" ? (
            <Stack
              sx={{
                maxHeight: shouldAddCustomHeightOnCurrentPage() ? "calc(100vh - 95px)" : "auto",
                overflow: "auto",
              }}
            >
              <Outlet />
            </Stack>
          ) : (
            <Outlet />
          )}
          {loggedInUserType === "customer" && (
            <Box className="bubble-popup">
              <Box
                onClick={handleBubble}
                component="img"
                src={
                  imageSrc[userAdditionalInfo?.accountManager?.userId]
                    ? imageSrc[userAdditionalInfo?.accountManager?.userId]
                    : `${s3ImagesBaseURL}/placeholder-avatar.svg`
                }
                width="70px"
                height="70px"
                sx={{ width: "70px", height: "70px" }}
              />

              {!openBubble && (
                <Box className="bubble-content">
                  {userAdditionalInfo?.accountManager?.userId ? (
                    <>
                      <p className="hand-wave">👋</p>
                      <div className="cross-button" onClick={handleBubble}>
                        <CrossButtonIcon />
                      </div>
                      <p>
                        Hello, I am <strong>{userAdditionalInfo?.accountManager?.firstName} !</strong> your new Account
                        Manager. Contact me in case you need any help!
                      </p>
                      <div className="contact">
                        <span>Email</span>
                        <div>
                          <p>{userAdditionalInfo?.accountManager?.email}</p>
                          <button
                            type="button"
                            onClick={() => copyToClipboard(userAdditionalInfo?.accountManager?.email)}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                      <div className="contact">
                        <span>Phone number</span>
                        <div>
                          <p>{userAdditionalInfo?.accountManager?.phoneNumber}</p>
                          <button
                            type="button"
                            onClick={() => copyToClipboard(userAdditionalInfo?.accountManager?.phoneNumber)}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                      <div className="tip-down">
                        <BubbleTipIcon />
                      </div>
                    </>
                  ) : (
                    <p style={{ marginBottom: "0" }}>Hi! You will be assigned with your account manager shortly.</p>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}
