import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Divider, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import EmployeeCard from "./EmployeeCover";
import { convertDate, getValue } from "components/Screens/CommonUtils";
import { usePermissions } from "components/Hook";
import PageLoader from "components/common/PageLoader";

import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";
import { baseUrl } from "util/APIBaseUrl";

import "./CompanyDetails.css";

function CompanyDetails() {
  // custom hook
  const { loggedInUser } = usePermissions();
  const dispatch = useDispatch();

  // redux state
  const employeeDetails = useSelector((state) => state.GetEmployeeDetials.employeeDetails);
  const isLoadingEmployeeDetails = useSelector((state) => state.GetEmployeeDetials.isLoadingEmployeeDetails);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const fillColor = "var(--color-primitives-primary-800)";

  useEffect(() => {
    dispatch(
      GetEmployeeById({
        employeeId: loggedInUser?.userId,
      })
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Company</title>
      </Helmet>
      {(loadingAdditionalInfo || isLoadingEmployeeDetails) && <PageLoader />}
      <div>
        <div className="ep-dash-company">
          <EmployeeCard
            // pictureUrl={`${baseUrl}/company/${employeeDetails?.customerInfo?.customerId}/logo`}
            pictureUrl={`${baseUrl}/customer/${userAdditionalInfo?.customerId}/logo?id=${userAdditionalInfo?.customerPictureVersion}`}
            name={getValue(employeeDetails?.companyName)}
            page="company"
          />
        </div>

        <div className="ep-company-details" style={{ justifyContent: "center" }}>
          <div className="ep-company-details-d1">
            <div>
              <div className="ep-company-details-d1a" id="company-name">
                {getValue(employeeDetails?.companyName)}
              </div>
              <div className="ep-company-details-d1b">Current Company Name</div>
            </div>
            {employeeDetails?.companyWebsite && (
              <a
                href={employeeDetails?.companyWebsite}
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
              >
                <div className="employee-company-web-link">
                  <div>Website Link</div>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.25 8.5V12.25C12.25 13.0784 11.5784 13.75 10.75 13.75H1.75C0.921573 13.75 0.25 13.0784 0.25 12.25V3.25C0.25 2.42157 0.921573 1.75 1.75 1.75H5.5V3.25H1.75V12.25H10.75V8.5H12.25ZM12.2478 2.81066L6.77815 8.28033L5.71749 7.21967L11.1872 1.75H7.74782V0.25H13.7478V6.25H12.2478V2.81066Z"
                      fill={fillColor}
                    />
                  </svg>
                </div>
              </a>
            )}
          </div>
          {employeeDetails?.hrpoc && Object.keys(employeeDetails?.hrpoc).length && (
            <div className="ep-company-details-d2">
              <div className="ep-company-details-d2a">
                <Avatar
                  alt={getValue(employeeDetails?.hrpoc?.First_Name)}
                  className="customer-profile-avatar-sidebar"
                  src={`${baseUrl}/user/${employeeDetails?.hrpoc?.Edge_Employee_ID}/logo?id=${employeeDetails?.hrpoc?.Picture_Version}&variant=thumbnail`}
                  sx={{ width: "63px", height: "63px" }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <div className="ep-company-details-d2b">Point of Contact</div>
                  <div className="ep-company-details-d2c" id="poc-name">
                    {`${getValue(employeeDetails?.hrpoc?.First_Name)} ${getValue(employeeDetails?.hrpoc?.Last_Name)}`}
                  </div>
                  <div className="ep-company-details-d2d " id="job-title-hr-poc">
                    {getValue(employeeDetails?.hrpoc?.Job_Title)}
                  </div>
                </div>
              </div>
              <div className="ep-hr-poc-contact">
                <div className="ep-hr-poc-email-div">
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.75 6.03685V4.25H2.25V6.0365L9.00002 9.41184L15.75 6.03685ZM15.75 7.7139L8.99998 11.0889L2.25 7.71358V14.75H15.75V7.7139ZM2.25 2.75H15.75C16.5784 2.75 17.25 3.42157 17.25 4.25V14.75C17.25 15.5784 16.5784 16.25 15.75 16.25H2.25C1.42157 16.25 0.75 15.5784 0.75 14.75V4.25C0.75 3.42157 1.42157 2.75 2.25 2.75Z"
                      fill={fillColor}
                    />
                  </svg>
                  <a
                    href={`mailto: ${getValue(employeeDetails?.hrpoc?.Email)} `}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <div className="ep-hr-poc-email" id="poc-email">
                      {getValue(employeeDetails?.hrpoc?.Email)}
                    </div>
                  </a>
                </div>
                <div className="ep-hr-poc-email-div">
                  {/* <img src={"../assets/Images/phone-icon.png"} /> */}

                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.25 1.25H12.75C13.5784 1.25 14.25 1.92157 14.25 2.75V16.25C14.25 17.0784 13.5784 17.75 12.75 17.75H5.25C4.42157 17.75 3.75 17.0784 3.75 16.25V2.75C3.75 1.92157 4.42157 1.25 5.25 1.25ZM7.5 2.75H5.25V16.25H12.75V2.75H10.5C10.5 3.16421 10.1642 3.5 9.75 3.5H8.25C7.83579 3.5 7.5 3.16421 7.5 2.75Z"
                      fill={fillColor}
                    />
                  </svg>

                  <div className="ep-hr-poc-email" id="poc-number">
                    {getValue(employeeDetails?.hrpoc?.Phone_Number)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="ep-company-details-d3">
            <Divider />
            <div className="ep-company-details-d3a">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 8H4V13H11H13H20V8H16H8ZM8 6V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8ZM11 15H4V19H20V15H13V16H11V15ZM14 6V5H10V6H14Z"
                  fill={fillColor}
                />
              </svg>

              <div className="ep-company-details-d3-title-value">
                <div className="ep-company-details-d3-title">Department</div>
                <div className="ep-company-details-d3-value" id="department">
                  {getValue(employeeDetails?.department)}
                </div>
              </div>
            </div>
            <Divider />
            <div className="ep-company-details-d3a">
              {/* <img src="../assets/Images/calendar.png" /> */}
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 6V3H16V4H14V3H6V4H4V3H2V6H18ZM18 8H2V18H18V8ZM16 1H18C19.1046 1 20 1.89543 20 3V18C20 19.1046 19.1046 20 18 20H2C0.89543 20 0 19.1046 0 18V3C0 1.89543 0.89543 1 2 1H4V0H6V1H14V0H16V1ZM7 12H5V10H7V12ZM11 12H9V10H11V12ZM15 12H13V10H15V12ZM7 16H5V14H7V16ZM11 16H9V14H11V16Z"
                  fill={fillColor}
                />
              </svg>

              <div className="ep-company-details-d3-title-value">
                <div className="ep-company-details-d3-title">Join Date</div>
                <div className="ep-company-details-d3-value" id="join-date">
                  {convertDate(employeeDetails?.startDate)}
                </div>
              </div>
            </div>
            <Divider />
            <div className="ep-company-details-d3a">
              {/* <img src="../assets/Images/jobTitle.png" /> */}
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 6V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V6C0.89543 6 0 5.10457 0 4V2C0 0.89543 0.89543 0 2 0H18C19.1046 0 20 0.89543 20 2V4C20 5.10457 19.1046 6 18 6ZM4 6V16H16V6H4ZM7 9V7H13V9H7ZM2 2V4H18V2H2Z"
                  fill={fillColor}
                />
              </svg>

              <div className="ep-company-details-d3-title-value">
                <div className="ep-company-details-d3-title">Designation</div>
                <div className="ep-company-details-d3-value">{getValue(employeeDetails?.jobTitle)}</div>
              </div>
            </div>
            <Divider />
            <div className="ep-company-details-d3a">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.4004 8.16665L10.8992 2.66543L5.39794 8.16665H5.39917V17.3333H16.3992V8.16665H16.4004ZM18.2325 9.99877V17.3333C18.2325 18.3458 17.4117 19.1666 16.3992 19.1666H5.39917C4.38665 19.1666 3.56584 18.3458 3.56584 17.3333V9.99875L2.21209 11.3525L0.916656 10.0571L9.60389 1.36983C10.3192 0.654474 11.4791 0.654474 12.1944 1.36983L20.8817 10.0571L19.5862 11.3525L18.2325 9.99877Z"
                  fill={fillColor}
                />
              </svg>

              <div className="ep-company-details-d3-title-value">
                <div className="ep-company-details-d3-title">Company Address</div>
                <div className="ep-company-details-d3-value">
                  {`${employeeDetails?.customerAddressLine1 || " "} ${employeeDetails?.customerAddressLine2 || " "}`}
                  <br />
                  {`${employeeDetails?.customerCity || " "} ${getValue(employeeDetails?.customerState)}`}
                  <br />
                  {employeeDetails?.country || " "}
                  <br />
                  {employeeDetails?.zip || ""}
                </div>
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
