import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import {
  Menu,
  MenuItem,
  Grid,
  IconButton,
  Avatar,
  Toolbar,
  Tooltip,
  TextField,
  InputAdornment,
  Typography,
  Link,
  Stack,
} from "@mui/material";

import { usePermissions } from "components/Hook";

import PageLoader from "components/common/PageLoader";
import SectionLoader from "components/common/SectionLoader";
import Employee from "components/Screens/Workforce/CustomerWorkforceV2/Employee";
import { checkUserAdditionalInfo, getValue, isEmptyObject, isValidResponse } from "components/Screens/CommonUtils";
import Notifications from "components/Widgets/Notifications";

import { LogoutUser } from "store/actions/Logout";
import { ResetSearchEmployeeAndCustomer, SearchEmployeeAndCustomer } from "store/actions/SearchEmployee&Customer";

import HamburgerMenuIcon from "assets/svgs/HamburgerMenuIcon";
import LogoutIcon from "assets/svgs/LogoutIcon";
import CrossIcon from "assets/svgs/CrossButtonIcon";
import ExpandSidebarMenuIcon from "assets/svgs/ExpandSidebarMenuIcon";

import { baseUrl } from "util/APIBaseUrl";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    zIndex: 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppHeader(props) {
  const { open, setOpen, setAnchorEl, anchorEl } = props;

  const ref = useRef(null);
  const ref1 = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sideBarRevamp } = useFlags();
  const { logout: auth0Logout, isAuthenticated } = useAuth0();
  const { canRead, loggedInUser, loggedInUserType } = usePermissions();

  const { permissions } = props?.userDetails || {};
  const hasAdminAndEdgeEmployeeAccess = permissions?.includes("EdgeAdmin") && permissions?.includes("EdgeEmployee");
  const hasCustomerAdminAccess = permissions?.includes("CustomerAdmin");

  const [timer, setTimer] = useState(null);
  const location = useLocation();
  const [width, setWidth] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [isSearchingEmployeesAndCustomers, setisSearchingEmployeesAndCustomers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const searchEmployeeAndCustomer = useSelector((state) => state.SearchEmployeeAndCustomer.searchEmployeeAndCustomer);

  const clearSearchField = () => {
    setSearchParam("");
    setShowDropdown(false);
  };

  const logoutUser = () => {
    setIsLoading(true);
    dispatch(LogoutUser());
    localStorage.setItem("communityLoginCheck", false);

    window.analytics?.track("Logged Out", {
      channel: "Button",
      name: loggedInUser?.name,
      userID: loggedInUser?.userId,
      email: loggedInUser?.email,
      accountType: loggedInUser?.type,
    });

    if (isAuthenticated) {
      auth0Logout({ returnTo: window.location.origin });
    }
  };

  const searchCustomerorEmployee = (event) => {
    setisSearchingEmployeesAndCustomers(true);
    setSearchParam(event.target.value);
    let searchRequest = {};
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      if (checkUserAdditionalInfo(userAdditionalInfo)) {
        const { value } = event.target;
        if (value && value.length > 2 && value != " ") {
          setShowDropdown(true);
          searchRequest = {
            customer: `customer/${userAdditionalInfo?.customerId}/employees/search?name=${value}`,
            edge: `employees/search?name=${value}`,
          };

          dispatch(
            SearchEmployeeAndCustomer({
              request: searchRequest[loggedInUserType],
              search: value,
              loaderFlag: false,
            })
          );
        } else if (value.length <= 2) {
          setShowDropdown(false);
          dispatch(ResetSearchEmployeeAndCustomer({}));
        }
      }
    }, 500);

    setTimer(newTimer);
  };

  useEffect(() => {
    const handleClickAway = (event) => {
      if (ref1.current && !ref1.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  useEffect(() => {
    if (searchEmployeeAndCustomer) {
      setisSearchingEmployeesAndCustomers(false);
    }
  }, [searchEmployeeAndCustomer]);

  useLayoutEffect(() => {
    if (canRead("searchBar")) {
      setWidth(ref?.current?.offsetWidth);
    }
  }, []);

  useEffect(() => {
    let userProfilePath = {};

    if (isEmptyObject(loggedInUser)) {
      userProfilePath = {
        customer: `${baseUrl}/customerUser/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`,
        edge: `${baseUrl}/user/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`,
        employee: `${baseUrl}/employee/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`,
      };
    }
    setUserProfileUrl(userProfilePath[loggedInUserType]);
  }, [loggedInUserType, loggedInUser, userAdditionalInfo]);

  return (
    <>
      {isLoading && <PageLoader />}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          color: "black",
          zIndex: "2",
          background:
            location?.pathname === "/main/community"
              ? "#191B1F"
              : "linear-gradient(0deg, rgba(191, 194, 255, 0.05), rgba(191, 194, 255, 0.05)), #FFFFFF",
          boxShadow: "0px 5px 0px rgba(191, 194, 255, 0.19)",
          display: location?.pathname === "/main/community" ? "none" : null,
        }}
      >
        <Toolbar sx={{ padding: "0px !important" }}>
          <Grid container className="app-bar-grid1">
            <Grid item sx={{ display: "flex" }}>
              {!sideBarRevamp && (
                <>
                  <ExpandSidebarMenuIcon />
                  <IconButton
                    onClick={() => {
                      props?.setCloseSideBarFromMenuIcon(true);
                      setOpen(!open);
                    }}
                    style={{
                      ...(open && {
                        position: "absolute",
                        left: "220px",
                        bottom: "7px",
                      }),
                      ...(!open && {
                        marginLeft: "30px",
                      }),
                      "@media screen and (max-width: 600px)": {
                        marginLeft: !open ? "0px !important" : null,
                      },
                    }}
                  >
                    <ExpandSidebarMenuIcon
                      style={{
                        fill: location?.pathname === "/main/community" ? "white" : "#14151F",
                      }}
                    />
                  </IconButton>
                </>
              )}
            </Grid>
            {canRead("searchBar") ? (
              <div ref={ref1}>
                <Grid
                  item
                  sx={{
                    ...(open && {
                      left: "140px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      width: "354px",
                      height: "36px",
                      "& .MuiTextField-root": {
                        margin: "0px",
                      },
                    }),
                    position: "relative",
                  }}
                >
                  <TextField
                    ref={ref}
                    variant="standard"
                    autoComplete="off"
                    value={searchParam}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "linear-gradient(0deg, rgba(73, 80, 199, 0.05), rgba(73, 80, 199, 0.05)), #FAFAFF;",

                        width: "354px",
                        height: "36px",
                        borderRadius: "30px",
                        fontFamily: "Poppins-Regular !important",
                      },
                      "& .MuiInputBase-input": {
                        paddingTop: "10px",
                        paddingLeft: "16px",
                        paddingBottom: "10px",
                        fontWeight: 400,
                        color: "#B2B4E2",
                        letterSpacing: "0.4px",
                        lineHeight: "16px",
                        fontSize: "12px",
                        fontFamily: "Poppins-Regular !important",
                      },
                    }}
                    className="inputSearch"
                    id="password"
                    placeholder="Enter a Keyword"
                    size="small"
                    name="search"
                    onChange={searchCustomerorEmployee}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchParam ? (
                            <div style={{ cursor: "pointer" }} onClick={clearSearchField}>
                              <CrossIcon />{" "}
                            </div>
                          ) : (
                            <IconButton aria-label="toggle password visibility" sx={{ paddingRight: "14px" }}>
                              <SearchIcon
                                fontSize="18px"
                                sx={{
                                  color: "var(--color-primitives-primary-900)",
                                }}
                              />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showDropdown && (
                    <Grid
                      sx={{
                        position: "absolute",
                        top: "60px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: " 0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
                        padding: "18px 0px",
                        borderRadius: "12px",
                        zIndex: 10,
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: { width },
                      }}
                    >
                      {/* TODO */}
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {isSearchingEmployeesAndCustomers ? (
                        <SectionLoader />
                      ) : isValidResponse(searchEmployeeAndCustomer) && searchEmployeeAndCustomer?.payload.length ? (
                        searchEmployeeAndCustomer?.payload?.map((employee) => {
                          return (
                            <Employee
                              setShowDropdown={setShowDropdown}
                              employee={employee}
                              clearSearchField={clearSearchField}
                            />
                          );
                        })
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            color: "var(--color-primitives-primary-600)",
                            textAlign: "center",
                          }}
                        >
                          No Result Found
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            ) : null}

            <Grid item>
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                {loggedInUserType === "edge" && <Notifications />}
                <Grid container item sx={{ width: "auto", alignItems: "center" }}>
                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",

                      gap: "4px",
                      background: "#14151F",
                      borderRadius: "42px",
                      width: "66px",
                      height: "36px",
                      alignItems: "center",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Grid item className="grid-avator-icon">
                      <Tooltip title="Account settings">
                        <IconButton sx={{ p: 0 }}>
                          <Avatar sx={{ width: "32px", height: "32px" }} src={userProfileUrl} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <HamburgerMenuIcon />
                  </Grid>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    sx={{
                      "& .MuiPaper-root": {
                        minWidth: "244px",
                      },
                      "& .MuiList-root": {
                        padding: "0px",
                      },
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        minWidth: 80,
                        borderRadius: "12px !important",
                        boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25) !important",
                        padding: "0px",
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          // eslint-disable-next-line quotes
                          content: '""',
                          display: "none",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem
                      disableRipple
                      disableTouchRipple
                      onClick={() => {
                        if (loggedInUserType === "customer") navigate("/main/profile");
                        else if (loggedInUserType === "employee") navigate("/main/eprofile");
                      }}
                      sx={{
                        padding: "10px 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        borderBottom: "0.5px solid #B2B4E2",
                        "&:hover": {
                          backgroundColor: "#FBFAFF",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "32px",
                          height: "32px",
                          margin: "0px !important",
                        }}
                        src={userProfileUrl}
                      />
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Medium",
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#2C2D3E",
                            letterSpacing: "0.5px",
                          }}
                        >
                          {getValue(loggedInUser?.name)}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "11px",
                            lineHeight: "16px",
                            color: "#2C2D3E",
                            letterSpacing: "0.5px",
                          }}
                        >
                          {getValue(loggedInUser?.email)}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      disableTouchRipple
                      sx={{
                        padding: "15px 0px",
                        borderBottom: "0.5px solid #B2B4E2",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Link
                          sx={{
                            textDecoration: "none",
                            padding: "5px 12px",
                            "&:hover": {
                              backgroundColor: "#FBFAFF",
                            },
                          }}
                          onClick={() => {
                            if (props?.userDetails?.type === "employee") {
                              navigate("/main/eprofile");
                            } else {
                              navigate("/main/profile");
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins-Medium",
                              fontSize: "13px",
                              lineHeight: "20px",
                              color: "#2C2D3E",
                              letterSpacing: "0.1px",
                            }}
                          >
                            Profile
                          </Typography>
                        </Link>

                        {props?.userDetails?.type != "employee" &&
                          (hasAdminAndEdgeEmployeeAccess || hasCustomerAdminAccess) && (
                            <Link
                              sx={{
                                textDecoration: "none",
                                padding: "5px 12px",
                                "&:hover": {
                                  backgroundColor: "#FBFAFF",
                                },
                              }}
                              onClick={() => {
                                navigate("/main/settings");
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins-Medium",
                                  fontSize: "13px",
                                  lineHeight: "20px",
                                  color: "#2C2D3E",
                                  letterSpacing: "0.1px",
                                }}
                              >
                                Manage users
                              </Typography>
                            </Link>
                          )}
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      disableTouchRipple
                      onClick={logoutUser}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "12px",
                        padding: "14px 12px",
                        "&:hover": {
                          backgroundColor: "#FBFAFF",
                          borderBottomLeftRadius: "12px",
                          borderBottomRightRadius: "12px",
                        },
                      }}
                    >
                      <LogoutIcon />
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Medium",
                          fontSize: "13px",
                          lineHeight: "20px",
                          color: "#2C2D3E",
                          letterSpacing: "0.1px",
                        }}
                      >
                        Log Out
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Stack>

              {/* </Grid> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
export default AppHeader;
