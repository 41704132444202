import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Divider, List, ListItem, Box } from "@mui/material";

import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import { getDateInFormat, getFullMonthYearFromDate } from "helpers";
import { numberToCurrencyFormat } from "util/utils";

import { EmployeePaySlipDetails } from "store/actions/EmployeePortal/EmployeePaySlipDetails";
import { showSnackbar } from "store/actions/Utility";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { MONTH_NAMES } from "constants";

import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import DownloadIcon from "assets/images/download.svg";
import BackButtonIcon from "assets/images/left-arrow.svg";

import "./EmployeePayStubDetails.css";

function EmployeeSlipsHeader({
  open,
  setOpen,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
  filters,
  setPayrollId,
  openMonth,
  setMonthOpen,
}) {
  return (
    <div className="bg-white p-1 mt-075 rounded-xl flex items-center">
      <p className="title-sm mr-05 neutral-60a">Filter</p>
      <div className="employee-stubs-grid-header">
        <div style={{ position: "relative" }}>
          <button
            id="year-paystubs"
            type="button"
            style={{
              border: "1px solid #777680",
              borderRadius: "22px",
              padding: "6px 10px 6px 16px",
              color: "#3D3E5C",
              backgroundColor: "transparent",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.1px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "column",
              borderBottom: `${open ? "none" : "1px solid #777680"}`,
              borderBottomLeftRadius: `${open ? "0px" : "22px"}`,
              borderBottomRightRadius: `${open ? "0px" : "22px"}`,
            }}
            onClick={() => setOpen(!open)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {selectedYear}
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#3D3E5C" />
              </svg>
            </div>
          </button>
          {open && (
            <List
              sx={{
                position: "absolute",
                top: "16px",
                left: "0px",
                padding: "12px 0px 16px",
                marginTop: "12px",
                listStyle: "none",
                width: "100%",
                border: "1px solid #777680",
                borderRadius: "22px",
                borderTop: `${open && "none"}`,
                borderTopLeftRadius: `${open && "0px"}`,
                borderTopRightRadius: `${open && "0px"}`,
                backgroundColor: "#FFFFFF",
              }}
            >
              {filters?.map((yearlyData) => {
                const year = yearlyData?.year;
                return (
                  <ListItem
                    onClick={() => {
                      setSelectedMonth(yearlyData?.months?.[0]?.month);
                      setSelectedYear(year);
                      setOpen(false);
                      setPayrollId(yearlyData?.months[0]?.payrollId);
                    }}
                    sx={{
                      padding: "6px 16px",
                      fontFamily: "Poppins-Medium",
                      fontSize: "14px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: selectedYear === year ? "var(--color-primitives-primary-500)" : "#3D3E5C",
                      backgroundColor: selectedYear === year ? "var(--color-primitives-primary-50)" : "unset",
                      "&:hover": {
                        backgroundColor: "var(--color-primitives-primary-50)",
                        color: "var(--color-primitives-primary-500)",
                      },
                    }}
                  >
                    {year}
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
        {/* ==== */}
        <div style={{ position: "relative", marginLeft: "12px" }}>
          <button
            id="year-paystubs"
            type="button"
            style={{
              width: "7rem",
              border: "1px solid #777680",
              borderRadius: "22px",
              padding: "6px 10px 6px 16px",
              color: "#3D3E5C",
              backgroundColor: "transparent",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.1px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "column",
              borderBottom: `${openMonth ? "none" : "1px solid #777680"}`,
              borderBottomLeftRadius: `${openMonth ? "0px" : "22px"}`,
              borderBottomRightRadius: `${openMonth ? "0px" : "22px"}`,
            }}
            onClick={() => setMonthOpen(!openMonth)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {MONTH_NAMES[selectedMonth]}
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#3D3E5C" />
              </svg>
            </div>
          </button>
          {openMonth && (
            <List
              sx={{
                position: "absolute",
                top: "16px",
                left: "0px",
                padding: "12px 0px 16px",
                marginTop: "12px",
                listStyle: "none",
                width: "100%",
                border: "1px solid #777680",
                borderRadius: "22px",
                borderTop: `${openMonth && "none"}`,
                borderTopLeftRadius: `${openMonth && "0px"}`,
                borderTopRightRadius: `${openMonth && "0px"}`,
                backgroundColor: "#FFFFFF",
              }}
            >
              {filters
                .find((f) => f.year === selectedYear)
                ?.months.map((month) => {
                  return (
                    <ListItem
                      onClick={() => {
                        setSelectedMonth(month.month);
                        setMonthOpen(false);
                        setPayrollId(month?.payrollId);
                      }}
                      sx={{
                        padding: "6px 16px",
                        fontFamily: "Poppins-Medium",
                        fontSize: "14px",
                        lineHeight: "20px",
                        cursor: "pointer",
                        color: selectedMonth === month.month ? "var(--color-primitives-primary-500)" : "#3D3E5C",
                        backgroundColor: selectedMonth === month.month ? "var(--color-primitives-primary-50)" : "unset",
                        "&:hover": {
                          backgroundColor: "var(--color-primitives-primary-50)",
                          color: "var(--color-primitives-primary-500)",
                        },
                      }}
                    >
                      {MONTH_NAMES[month.month]}
                    </ListItem>
                  );
                })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

function EmployeePayStubDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: payrollIdFromParams } = useParams();
  const { loggedInUser } = usePermissions();

  const [payrollId, setPayrollId] = useState(payrollIdFromParams);
  const [open, setOpen] = useState(false);
  const [openMonth, setMonthOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();

  const paySlipDetails = useSelector((state) => state.EmployeePaySlipDetails);
  const loadingPaySlipDetails = useSelector((state) => state.EmployeePaySlipDetails.isLoading);

  const { payslip } = paySlipDetails;
  const { data: payslipData, filters = [] } = payslip || {};

  const headerContainerStyle = { background: "var(--color-primitives-primary-200)" };
  const personalPayrollInfoStyle = { background: "var(--color-primitives-primary-50)" };

  const downloadPayslip = async () => {
    const downloadPayrollId = payslipData?.payroll?.id;
    const response = await getRequest(
      `${baseUrl}/employee/${loggedInUser?.userId}/payroll/${downloadPayrollId}?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Salary Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    dispatch(showSnackbar({ dispatch, type: "success", message: "Pay slip downloaded successfully!" }));
  };

  useEffect(() => {
    if (payrollId) dispatch(EmployeePaySlipDetails(`employee/${loggedInUser?.userId}/payroll/${payrollId}`));
  }, [payrollId]);

  useEffect(() => {
    if (!payslipData) return;
    setSelectedYear(new Date(payslipData?.payroll?.month)?.getUTCFullYear());
    setSelectedMonth(new Date(payslipData?.payroll?.month)?.getUTCMonth());
  }, [payslipData]);
  return (
    <>
      <Helmet>
        <title>{`Edge | Pay Slip - ${getFullMonthYearFromDate(payslipData?.payroll?.month)}`}</title>
      </Helmet>
      {loadingPaySlipDetails && <PageLoader />}

      <div className="employee-payslip-details-container">
        <div className="employee-paystubs-page-container">
          <div className="flex items-center space-x-1 pt-05">
            <Box className="cursor-pointer" component="img" src={BackButtonIcon} onClick={() => navigate(-1)} />
            <div className="employee-paystubs-dA1-Menu">
              Pay Slip - {getFullMonthYearFromDate(payslipData?.payroll?.month)}
            </div>
          </div>
          <EmployeeSlipsHeader
            open={open}
            setOpen={setOpen}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            filters={filters}
            setPayrollId={setPayrollId}
            openMonth={openMonth}
            setMonthOpen={setMonthOpen}
          />
          <br />

          <div className="emp-payslip-container">
            <div className="emp-payslip-header-container" style={headerContainerStyle}>
              <div className="flex">
                <div className="emp-payslip-edge-logo">
                  <EdgeLogo icon size="xl" />
                </div>

                <div className="align-self-center pl-1">
                  <div className="emp-payslip-title">Pay slip</div>
                  <div className="emp-payslip-month">{getFullMonthYearFromDate(payslipData?.payroll?.month)}</div>
                </div>
              </div>

              <div className="emp-payslip-download-btn cursor-pointer" onClick={downloadPayslip}>
                <img src={DownloadIcon} alt="download icon" />
                Download PDF
              </div>
            </div>

            <div className="personal-payroll-info-container" style={personalPayrollInfoStyle}>
              <div>
                <div className="personal-payroll-info-title">Personal Information</div>

                <div className="payroll-info-container">
                  <div>Name</div>
                  <div>
                    {payslipData?.user?.firstName} {payslipData?.user?.lastName}
                  </div>
                </div>

                <div className="payroll-info-container">
                  <div>Designation</div>
                  <div>{payslipData?.user?.jobTitle}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Employee Type</div>
                  <div>{payslipData?.user?.employmentType}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Department</div>
                  <div>{payslipData?.user?.department}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Employee Code</div>
                  <div>{payslipData?.user?.id}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Date of Joining</div>
                  <div>{getDateInFormat(payslipData?.user?.joiningDate)}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Date of Birth</div>
                  <div>{getDateInFormat(payslipData?.user?.DOB)}</div>
                </div>
              </div>

              <div>
                <div className="personal-payroll-info-title">Payroll Information</div>

                <div className="payroll-info-container">
                  <div>Monthly Salary</div>
                  <div>${numberToCurrencyFormat(payslipData?.payroll?.monthlySalary)}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Net Amount</div>
                  <div>${numberToCurrencyFormat(payslipData?.payroll?.netAmount)}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Account #</div>
                  <div>{payslipData?.payroll?.account || "N/A"}</div>
                </div>

                <div className="payroll-info-container">
                  <div>Bank</div>
                  <div>{payslipData?.payroll?.bank || "N/A"}</div>
                </div>
              </div>
            </div>

            <div className="payslip-details-heading">
              <div className="payslip-details-section1-heading">Allowance</div>
              <div className="payslip-details-section2-heading">Current Month</div>
              <div className="payslip-details-section3-heading">YTD</div>
            </div>

            {payslipData?.allowance?.map((allowance) => (
              <div className="payslip-details">
                <div className="payslip-details-data" style={{ textAlign: "left" }}>
                  {allowance?.name}
                  <Divider light />
                </div>
                <div className="payslip-details-data" style={{ width: "200px" }}>
                  ${numberToCurrencyFormat(allowance?.amount)}
                  <Divider light />
                </div>
                <div className="payslip-details-data" style={{ width: "265px" }}>
                  ${numberToCurrencyFormat(allowance?.ytd)}
                  <Divider light />
                </div>
              </div>
            ))}

            <div className="flex gap-1">
              <div className="payslip-details-total">Total</div>
              <div className="payslip-details-total" style={{ width: "200px" }}>
                ${numberToCurrencyFormat(payslipData?.total?.totalAllowance)}
              </div>
              <div className="payslip-details-total" style={{ width: "265px" }}>
                ${numberToCurrencyFormat(payslipData?.total?.totalAllowanceYTD)}
              </div>
            </div>

            <div className="payslip-details-heading mt-1">
              <div className="payslip-details-section1-heading">Deductions</div>
              <div className="payslip-details-section2-heading">Current Month</div>
              <div className="payslip-details-section3-heading">YTD</div>
            </div>

            {payslipData?.deductions?.map((deduction) => (
              <div className="payslip-details">
                <div className="payslip-details-data" style={{ textAlign: "left" }}>
                  {deduction?.name}
                  <Divider light />
                </div>
                <div className="payslip-details-data" style={{ width: "200px" }}>
                  ${numberToCurrencyFormat(deduction?.amount)}
                  <Divider light />
                </div>
                <div className="payslip-details-data" style={{ width: "265px" }}>
                  ${numberToCurrencyFormat(deduction?.ytd)}
                  <Divider light />
                </div>
              </div>
            ))}

            <div className="flex gap-1">
              <div className="payslip-details-total">Total</div>
              <div className="payslip-details-total" style={{ width: "200px" }}>
                ${numberToCurrencyFormat(payslipData?.total?.totalDeductions)}
              </div>
              <div className="payslip-details-total" style={{ width: "265px" }}>
                ${numberToCurrencyFormat(payslipData?.total?.totalDeductionsYTD)}
              </div>
            </div>

            <div className="payslip-summary-container">
              <div className="payslip-summary-details" style={{ height: "44px" }}>
                <div className="payslip-details-section1-heading grid-center">Summary</div>
                <div className="payslip-details-section2-heading h-2 grid-center">Current Month</div>
              </div>

              <div className="payslip-summary-details">
                <div className="payslip-details-section2-heading text-left" style={{ textAlign: "left" }}>
                  Total Allowance <Divider light />
                </div>
                <div className="payslip-details-section2-heading h-2" style={{ width: "30%" }}>
                  ${numberToCurrencyFormat(payslipData?.total?.totalAllowance)}
                  <Divider light />
                </div>
              </div>

              <div className="payslip-summary-details">
                <div className="payslip-details-section2-heading text-left" style={{ textAlign: "left" }}>
                  Total Deductions <Divider light />
                </div>
                <div className="payslip-details-section2-heading h-2" style={{ width: "30%" }}>
                  ${numberToCurrencyFormat(payslipData?.total?.totalDeductions)} <Divider light />
                </div>
              </div>

              <div className="payslip-summary-details" style={{ height: "44px" }}>
                <div className="payslip-details-net-amount grid-center">Net Amount</div>
                <div className="payslip-details-net-amount grid-center">
                  ${numberToCurrencyFormat(payslipData?.total?.netAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeePayStubDetails;
