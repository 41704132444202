import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Divider, Grid, Typography } from "@mui/material";

import NotificationMessage from "components/Screens/DashBoard/CustomerDashBoardV2/NotificationMessage";

import { GetNoticeBoard } from "store/actions/DashBoard/GetNoticeBoard";
import { GetAllHolidays } from "store/actions/Holidays";

import { usePermissions } from "components/Hook";

import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import { getOrdinalSuffix } from "util/utils";
import { getHolidayDate, getHolidayDay, getHolidayEndDate, getHolidaysDate, getMonthFromDate } from "../../CommonUtils";

import "./Notifications.css";

function Notifications() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [notifications, setNotifications] = useState([]);

  const { loggedInUserType, loggedInUser } = usePermissions();

  const noticeBoard = useSelector((state) => state.GetNoticeBoard.noticeBoard);
  const allHolidays = useSelector((state) => state.Holidays.allHolidays);

  const dateTimeToMilliseconds = (dateTimeString) => {
    const unixTimestamp = Date.parse(dateTimeString);
    return unixTimestamp;
  };

  const getTimePassed = (t) => {
    const messageTime = new Date(t).toLocaleString();
    const receivedTime = dateTimeToMilliseconds(messageTime);
    const currentTime = Date.now();
    const timeDifference = currentTime - receivedTime;
    const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursPassed < 24) {
      return `${hoursPassed} hours ago`;
    }
    const daysPassed = Math.floor(hoursPassed / 24);
    return `${daysPassed} day(s) ago`;
  };

  const isHoliday = (holidays) => {
    if (holidays?.success && holidays?.data.length) {
      return true;
    }
    return false;
  };

  // functions
  React.useEffect(() => {
    let holidaysRequest = "";
    if (loggedInUserType === "customer") {
      holidaysRequest = `${baseUrl}/customer/${loggedInUser?.customerId}/publicHolidays`;
    } else if (loggedInUserType === "employee") {
      holidaysRequest = `${baseUrl}/employee/publicHolidays`;
    }

    dispatch(
      GetAllHolidays({
        request: holidaysRequest,
        region: "",
        startDate: getHolidaysDate()?.startDate,
        endDate: getHolidaysDate()?.endDate,
      })
    );
  }, [loggedInUserType, loggedInUser]);

  const getEmployeeNoticeboard = async () => {
    const announcements = await getRequest(`${baseUrl}/announcements`);
    const { ok, payload } = announcements;
    if (ok) {
      const tempNotifications = [];
      payload.map(({ Announcement_ID: announcementID, Date, Body }) =>
        tempNotifications.push({
          id: announcementID,
          date: Date,
          message: Body,
        })
      );

      setNotifications(tempNotifications);
    }
  };

  useEffect(() => {
    if (loggedInUserType === "customer")
      dispatch(
        GetNoticeBoard({
          request: `customer/${loggedInUser?.customerId}/noticeboard`,
        })
      );
    else getEmployeeNoticeboard();
  }, []);

  useEffect(() => {
    if (noticeBoard.ok) {
      const tempNotifications = [];
      noticeBoard.payload.map(({ id, date, notice }) =>
        tempNotifications.push({
          id,
          date,
          message: notice,
        })
      );

      setNotifications(tempNotifications);
    }
  }, [noticeBoard]);

  return (
    <Grid
      container
      sx={{
        width: "328px",
        padding: "16px 12px",
        height: "calc(100vh - 46px)",
        overflowY: "scroll",
        overflowX: "hidden",
        background: "var(--color-primitives-primary-50)",
      }}
    >
      <Grid
        sx={{
          borderRadius: "15px",
          width: "-webkit-fill-available",
          height: "150px",
        }}
      >
        <Grid sx={{ position: "relative" }}>
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "15px",
              lineHeight: "22px",
              color: "#14151F",
              textAlign: "center",
              position: "absolute",
              margin: "auto",
              top: "30px",
              left: 0,
              width: "100%",
            }}
            className="z-1"
          >
            Notifications
          </Typography>
          <Box
            sx={{
              width: "100%",
            }}
            className="notification-head"
            component="img"
            src={`${s3ImagesBaseURL}/notification-head.png`}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "0px 12px",
          marginTop: "-90px",
          width: "-webkit-fill-available",
          zIndex: 1,
          height: "calc(100vh - 150px)",
        }}
      >
        <Grid
          sx={{
            background: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "-9.34286px 0.934286px 31.7657px rgba(2, 0, 110, 0.06)",
            padding: "16px 0px",
            minHeight: notifications?.ok && notifications?.payload.length ? "452px" : "200px",
          }}
        >
          <Grid
            sx={{
              maxHeight: "420px",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {notifications.length ? (
              notifications.map(({ id, date, message }) => {
                return <NotificationMessage key={id} message={message} label={getTimePassed(date)} />;
              })
            ) : (
              <Box
                sx={{
                  minHeight: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  id="no-new-notifications"
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#918F9A",
                  }}
                >
                  No Notification Yet
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ margin: "12px 0px" }} />
        <Grid
          sx={{
            background: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "-9.34286px 0.934286px 31.7657px rgba(2, 0, 110, 0.06)",
            padding: "8px 10px 16px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "15px",
              lineHeight: "22px",
              color: "#2C2D3E",
              padding: "8px",
            }}
          >
            {`Upcoming Holidays(${isHoliday(allHolidays) ? allHolidays?.data?.length : "0"})`}
          </Typography>
          {allHolidays?.success ? (
            <>
              <Grid
                sx={{
                  padding: "4px 8px",
                  display: "flex",
                  alignItems: "start",
                  gap: "14px",
                }}
              >
                <Box
                  component="img"
                  src={`${s3ImagesBaseURL}/notification-2.png`}
                  sx={{ width: "42px", height: "42px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {isHoliday(allHolidays) ? (
                    <>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Medium",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "19px",
                          color: "#14151F",
                        }}
                        id="holiday-name"
                      >
                        {allHolidays?.data[0]?.Name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "19px",
                          color: "#14151F",
                        }}
                      >
                        {`${allHolidays?.data[0]?.employeesCount} Employees will be on this holiday`}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "15px",
                          color: "var(--color-primitives-primary-600)",
                        }}
                      >
                        {allHolidays?.data.length
                          ? `${getHolidayDate(allHolidays?.data[0]?.StartDate)}${getOrdinalSuffix(
                              getHolidayDate(allHolidays?.data[0]?.StartDate)
                            )}  ${getMonthFromDate(allHolidays?.data[0]?.StartDate)}  (${getHolidayDay(
                              allHolidays?.data[0]?.StartDate
                            )})  ${getHolidayEndDate(allHolidays?.data[0]?.EndDate)} `
                          : "-"}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "19px",
                        color: "#14151F",
                      }}
                    >
                      {" No holiday this Month"}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Poppins-Medium",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19px",
                  color: "var(--color-primitives-primary-600)",
                  textAlign: "center",
                  marginTop: "16px",
                  cursor: "pointer",
                }}
                onClick={() => navigateTo("/main/holidays")}
              >
                View All
              </Typography>
            </>
          ) : null}
        </Grid>
        <Divider sx={{ margin: "12px 0px", borderColor: "transparent" }} />
      </Grid>
    </Grid>
  );
}

export default Notifications;
