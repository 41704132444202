import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";
import DocumentInfoSection from "components/Widgets/BambooHRTabs/Documents/DocumentInfoSection";

import { deleteEmployeeDocument } from "services/BambooHrTabs/documents";
import { showSnackbar } from "store/actions/Utility";

import "../Document.scss";

function DocumentDeleteModal({ open, onClose, document, employeeId, onPostDelete }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { documentName, shareWithEmployee, shareWithCustomer, documentExtension, createdAt } = document || {};

  const getShareText = () => {
    if (shareWithEmployee && shareWithCustomer) {
      return "Shared with Employee and Customer";
    }
    if (shareWithEmployee) {
      return "Shared with Employee";
    }
    if (shareWithCustomer) {
      return "Shared with Customer";
    }
    return "";
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);
      await deleteEmployeeDocument(employeeId, document?.documentId);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document deleted successfully", dispatch }));

      onPostDelete();
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} title="Delete Document?" width="552px">
      <DocumentInfoSection
        className="my-1"
        text={getShareText()}
        documentName={documentName}
        date={createdAt}
        documentExtension={documentExtension}
      />
      <div className="flex justify-end mt-05 mb-05">
        <Button label="Cancel" variant="outlined" size="large" color="inherit-text" onClick={onClose} />
        <div className="ml-05">
          <Button isLoading={isLoading} label="Delete" size="large" color="error" onClick={onDelete} />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentDeleteModal;
