import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import Header from "../../Header/Header";
import PageHeader from "../PageHeader";

import MangeRoles from "./MangeRoles";
import CompanyProfile from "./CompanyProfile";

function ManageUsersPage() {
  const [option, selectedOption] = useState("");
  const [pageHeader, setPageHeader] = useState(["Company Profile"]);

  const selectPageHeading = (opt) => {
    selectedOption(opt);
  };

  useEffect(() => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser?.type == "customer") {
      selectedOption("Company Profile");
      if (loggedInUser?.permissions?.includes("CustomerAdmin")) {
        setPageHeader(["Company Profile", "Manage Users"]);
      } else {
        setPageHeader(["Company Profile"]);
      }
    } else if (loggedInUser?.type == "edge") {
      setPageHeader(["Manage Users"]);
      selectedOption("Manage Users");
    }
  }, []);

  return (
    <Grid container direction="column" rowSpacing={3}>
      <Header
        data={{
          heading: "Manage users",
        }}
      />
      <PageHeader pageHeadings={pageHeader} selectPageHeading={selectPageHeading} selectedOption={option} />
      <Grid item container direction="row">
        <Grid item xs={12}>
          {option === "Manage Users" && <MangeRoles />}
          {option === "Company Profile" && <CompanyProfile />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ManageUsersPage;
