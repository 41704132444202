import React from "react";
import { LinearProgress } from "@mui/material";

import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  PictureAsPdfOutlined as PictureAsPdfOutlinedIcon,
  RefreshOutlined as RefreshOutlinedIcon,
} from "@mui/icons-material";

import CustomTooltip from "components/common/Tooltip";

import { getFileSizeToDisplay, pluralize } from "util/utils";

import "./drag-drop.scss";

const calculateStatsForFiles = (files) => {
  return {
    uploaded: files.filter((file) => file.status === "complete").length || false,
    uploading: files.filter((file) => file.status == "uploading").length || false,
    failed: files.filter((file) => file.status === "failed").length || false,
  };
};

function getFileStatus({ error, size, status }) {
  if (error) {
    return (
      <>
        <span className="file-info-error">{error}</span>
        <span className="dot-span file-info-error"> • </span>
        <span className="file-info-error">Failed</span>
      </>
    );
  }
  return (
    <>
      <span className="file-size">{getFileSizeToDisplay(size)}</span>
      <span className="dot-span"> • </span>
      <span className="file-info-complete">{status === "uploading" ? "Uploading" : "Complete"}</span>
    </>
  );
}

function renderStatus(files) {
  const { uploaded, uploading, failed } = calculateStatsForFiles(files);

  return (
    <div className="flex para-body-m-medium py-1">
      {uploaded && (
        <span>
          {uploaded} {pluralize("item", uploaded)} uploaded&nbsp;
        </span>
      )}
      {uploaded && (uploading || failed) && <>•&nbsp;</>}
      {uploading && (
        <span>
          Uploading {uploading} {pluralize("item", uploading)}&nbsp;
        </span>
      )}
      {uploading && failed && <>•&nbsp;</>}{" "}
      {failed && (
        <span className="file-status-error">
          {failed} {pluralize("upload", failed)} failed{" "}
        </span>
      )}
    </div>
  );
}

function SelectedFile({ files = [], onFileDelete, isMultiFileSupported, onFileSelect, loading, showButtons = true }) {
  return (
    <>
      {isMultiFileSupported && files.length > 0 && <> {renderStatus(files)}</>}
      <div className={`uploader-files ${isMultiFileSupported && files.length > 0 && "uploader-files-border"}`}>
        {files.map((file) => {
          const { id, fileName, error, size, progress = 0, retry = false, status } = file;
          return (
            <div className="file-item" key={id}>
              <div className={error ? "file-icon-red" : "file-icon"}>
                {isMultiFileSupported ? (
                  <PictureAsPdfOutlinedIcon className={`${status == "uploading" ? "opacity-50" : ""}`} />
                ) : (
                  <UploadFileIcon />
                )}
              </div>
              <div className="file-details">
                <div className="file-name-success">{fileName}</div>
                <div className="file-size-status">{getFileStatus({ error, size, loading, status })}</div>
                {(error || status === "uploading") && (
                  <LinearProgress
                    classes={{
                      root: `${error && "progress-root-error"} custom-linear-progress`,
                      bar: error ? "progress-bar-error" : "progress-bar-complete",
                    }}
                    variant="determinate"
                    value={progress}
                  />
                )}
              </div>

              {showButtons && (
                <div className="file-remove">
                  {status === "uploading" && (
                    <CustomTooltip
                      content={<div className="para-body-xs-medium">Cancel upload</div>}
                      arrow={false}
                      placement="bottom"
                    >
                      <CloseIcon onClick={() => onFileDelete(id)} />
                    </CustomTooltip>
                  )}

                  {(error || (status === "complete" && !retry)) && (
                    <CustomTooltip
                      content={<div className="para-body-xs-medium">Remove</div>}
                      className="ml-1"
                      arrow={false}
                      placement="bottom"
                    >
                      <DeleteIcon onClick={() => onFileDelete(id)} />
                    </CustomTooltip>
                  )}

                  {retry && (
                    <CustomTooltip
                      content={<div className="para-body-xs-medium">Retry upload</div>}
                      arrow={false}
                      placement="bottom"
                    >
                      <RefreshOutlinedIcon
                        className="ml-1"
                        onClick={() => {
                          onFileSelect([file]);
                        }}
                      />
                    </CustomTooltip>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SelectedFile;
