import React, { useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";

import { MenuList, MenuItem } from "components/common/Menu";

function Select({
  name,
  label,
  helperText,
  options,
  isOptional,
  topChoiceOptions = [],
  placeholder,
  value: propValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled = false,
  multiple = false,
  isSmallScreen = false,
  isDense = false,
  isDisGutters = false,
  hasDivider = false,
  leftSlot = null,
  rightSlot = null,
  startIcon = null,
  endIcon = null,
  ...other
}) {
  const formContext = useFormContext();
  const textFieldRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const renderSelect = (field = {}, fieldError) => {
    const currentValue = field.value ?? "";

    const handleChange = (newValue) => {
      field.onChange(newValue);
      handleClose();
    };

    return (
      <FormControl fullWidth data-testid="form-control">
        <InputLabel
          shrink
          htmlFor={name}
          className="paragraph text-left text-md font-medium line-height-lg mb-025 pos-relative non-transform"
        >
          {`${label} ${isOptional ? "(optional)" : ""}`}
        </InputLabel>
        <div className="ant-text-component mui-theme-border-colors">
          <TextField
            {...field}
            select
            id={name}
            name={name}
            disabled={disabled}
            error={!!fieldError}
            helperText={fieldError ? fieldError.message : helperText}
            value={currentValue}
            size={size}
            variant={variant}
            ref={textFieldRef}
            SelectProps={{
              multiple,
              open: isOpen,
              onClose: handleClose,
              onOpen: handleOpen,
              displayEmpty: true,
              renderValue: (selected) => {
                if (selected === "" || (Array.isArray(selected) && selected.length === 0)) {
                  return <span className="placeholder">{placeholder}</span>;
                }
                return Array.isArray(selected) ? selected.join(", ") : selected;
              },
              IconComponent: endIcon ? () => endIcon : undefined,
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: "248px",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              },
            }}
            InputProps={{
              ...field.InputProps,
              classes: {
                root: `custom-text-input-root text-left text-field-padding text-field-input-${size} ${disabled ? "disabled-input" : ""}`,
                input: "paragraph font-regular text-lg line-height-xl flex mr-15",
                focused: "custom-text-input-focused",
                error: "custom-text-input-error",
              },
              startAdornment: startIcon && currentValue && (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ),
              "data-testid": "text-field",
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              classes: {
                root: "paragraph text-sm font-regular line-height-sm error-text-color ml-0",
              },
            }}
            {...other}
          >
            {!disabled && (
              <MenuList autoWidth>
                {topChoiceOptions?.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.label}
                    onClick={() => handleChange(option.value)}
                    isSelected={currentValue === option.value}
                    isSmallScreen={isSmallScreen}
                    isDense={isDense}
                    isDisGutters={isDisGutters}
                    hasDivider={hasDivider}
                    isDisabled={false}
                    leftSlot={leftSlot}
                    rightSlot={rightSlot}
                  />
                ))}
                {topChoiceOptions?.length > 0 && options?.length > 0 && <Divider className="select-divider" />}
                {options?.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.label}
                    onClick={() => handleChange(option.value)}
                    isSelected={currentValue === option.value}
                    isSmallScreen={isSmallScreen}
                    isDense={isDense}
                    isDisGutters={isDisGutters}
                    hasDivider={hasDivider}
                    isDisabled={false}
                    leftSlot={leftSlot}
                    rightSlot={rightSlot}
                  />
                ))}
              </MenuList>
            )}
          </TextField>
        </div>
      </FormControl>
    );
  };

  return formContext && !propValue && !propOnChange ? (
    <Controller
      name={name}
      control={formContext.control}
      defaultValue={multiple ? [] : ""}
      render={({ field, fieldState: { error } }) => renderSelect(field, error)}
    />
  ) : (
    renderSelect({ value: propValue, onChange: propOnChange }, propError ? { message: helperText } : null)
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  topChoiceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  isOptional: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  isDense: PropTypes.bool,
  isDisGutters: PropTypes.bool,
  hasDivider: PropTypes.bool,
  leftSlot: PropTypes.node,
  rightSlot: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

Select.defaultProps = {
  helperText: "",
  placeholder: "Select",
  value: "",
  onChange: undefined,
  error: false,
  size: "small",
  variant: "outlined",
  disabled: false,
  multiple: false,
  topChoiceOptions: [],
  isOptional: false,
  isSmallScreen: false,
  isDense: false,
  isDisGutters: false,
  hasDivider: false,
  leftSlot: null,
  rightSlot: null,
  startIcon: null,
  endIcon: null,
};

export default Select;
