import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";

import { Divider, Box, InputAdornment } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import PageLoader from "components/common/PageLoader";
import Text from "components/common/Text";
import TopHeader from "components/common/TopHeader";

import { getCountryFlag } from "../CommonUtils";
import { getDateInFormat } from "helpers";

import { EmployeePeers } from "store/actions/EmployeePeers";
import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";

import "./EmployeePeersRevamped.scss";

function EmployeePeersGridA(props) {
  const { row: { employeeId, pictureVersion } = {} } = props;

  const [imageSrc, setImageSrc] = useState("");
  const [isPlaceHolderImage, setIsPlaceHolderImage] = useState(false);

  useEffect(() => {
    setImageSrc(`${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}`);
  }, [pictureVersion]);

  const handleImageError = () => {
    setIsPlaceHolderImage(true);
    setImageSrc(`${s3ImagesBaseURL}/placeholder-avatar.svg`);
  };

  return (
    <div className="employee-peers-cella">
      <img
        alt={props?.row?.firstName}
        src={imageSrc}
        onError={handleImageError}
        className={isPlaceHolderImage ? "avatar-image avatar-contain" : "avatar-image avatar-cover"}
      />
      <Box className="country-icon-box">{getCountryFlag(props?.row?.country)}</Box>
      <div className="peer-info">
        <div className="peer-name" id="peer-name">{`${props?.row?.firstName}  ${props?.row?.lastName}`}</div>
        <div className="peer-designation">{props?.row?.employmentDetails?.designation}</div>
        <div className="peer-join-date" id="peer-join-date">{`Join Date: ${getDateInFormat(
          props?.row?.employmentDetails?.joiningDate
        )}`}</div>
      </div>
    </div>
  );
}

function EmployeePeersGridB(props) {
  return (
    <div className="employee-peers-cellb">
      <div className="mail-icon">
        <MailOutlineIcon />
      </div>
      <div className="employee-email">
        <a
          className="employee-email"
          id="peer-email"
          target="_blank"
          href={`mailto:${props?.row?.email}`}
          rel="noreferrer"
        >
          {props?.row?.email}
        </a>
      </div>
    </div>
  );
}

function EmployeePeersListRevamped() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const employeePeers = useSelector((state) => state.EmployeePeers);
  const loadingEmployeePeers = useSelector((state) => state.EmployeePeers.isLoadingData);
  const isFlagTeamManagement = true;

  const searchPeers = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const params = new URLSearchParams();
    params.set("searchKeyword", e.target.value);
    const url = `employee/${loggedInUser?.userId}/peers?${params}&limit=6&page=1`;
    const employeePeersEndPoint = {
      url,
      isFlagTeamManagement,
    };

    dispatch(EmployeePeers(employeePeersEndPoint));
    setPage(1);
  };

  const fetchEmployeePeers = (pageNumber) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const url = `employee/${loggedInUser.userId}/peers?limit=6&page=${pageNumber}`;
    const employeePeersEndPoint = {
      url,
      isFlagTeamManagement,
    };

    dispatch(EmployeePeers(employeePeersEndPoint));
  };

  useEffect(() => {
    fetchEmployeePeers(page);
  }, [page]);

  useEffect(() => {
    if (employeePeers?.peers?.count) {
      const newTotalPages = Math.ceil(employeePeers.peers.count / 6);
      setTotalPages(newTotalPages);
      if (page > newTotalPages) {
        setPage(newTotalPages);
      }
    }
  }, [employeePeers?.peers?.count]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className="peers-revamped-container">
      <Helmet>
        <title>Edge | Peers</title>
      </Helmet>
      {loadingEmployeePeers == true && <PageLoader />}
      <div className="employee-paystubs-details-revamped">
        <TopHeader title="Team" linkName="Team" link="/main/peers" breadcrumbs backIcon="/main/peers" />

        <Divider sx={{ margin: "0px 0px 10px 0px" }} />
        <div className="peers-wrapper">
          <div className="employee-paystubs-search">
            <Text
              type="search"
              id="search-peers"
              onChange={searchPeers}
              name="searchPeers"
              placeholder="Employee name or email address "
              InputProps={{
                classes: {
                  input: "peer-input-prop",
                  focused: "custom-text-input-focused",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <div className="peer-search-icon">
                      <SearchOutlinedIcon />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          {!loadingEmployeePeers && employeePeers?.peers?.count !== 0 ? (
            <div className="employee-peers-pagination">
              <div className="peers-all-result">
                All results ({Math.min(6 * page, employeePeers?.peers?.count)}/{employeePeers?.peers?.count})
              </div>

              <div className="peers-pagination-icons-wrapper">
                <ChevronLeftOutlinedIcon
                  className={page === 1 ? "peers-pagination-icon-disabled" : "peers-pagination-icon-enabled"}
                  onClick={page > 1 ? handlePreviousPage : undefined}
                />
                <ChevronRightOutlinedIcon
                  className={page === totalPages ? "peers-pagination-icon-disabled" : "peers-pagination-icon-enabled"}
                  onClick={page < totalPages ? handleNextPage : undefined}
                />
              </div>
            </div>
          ) : null}

          {employeePeers.peers?.list?.map((row) => (
            <div className="employee-peers-list-revamped">
              <EmployeePeersGridA row={row} />
              <EmployeePeersGridB row={row} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EmployeePeersListRevamped;
