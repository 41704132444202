import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import AvatarMenu from "components/Screens/CustomerOnboarding/Layout/AvatarMenu";
import Sidebar from "components/Screens/CustomerOnboarding/Sidebar/CustomerOnboardingSideBar";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import { getUserAdditionalInfo } from "selectors/user";

import { VALIDATE_TOKEN_CASES } from "components/Screens/CustomerOnboarding/constants";

import {
  CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE,
  CUSTOMER_ONBOARDING_PAYMENT_SUCCESS_ROUTE,
  LOGIN_ROUTE,
} from "constants/routes";

import { getLoggedInUserDetailsFromLocalStorage } from "helpers";

import { baseUrl } from "util/APIBaseUrl";

import "./Layout.scss";

function HeaderLogo() {
  return <EdgeLogo size="md" state="dark" />;
}

function Layout({ title, children, isMeetingCreated, contentClass = "" }) {
  const location = useLocation();
  const navigate = useNavigate();
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);

  const loggedInUser = getLoggedInUserDetailsFromLocalStorage();
  const { name = "", email = "" } = loggedInUser;
  const { pictureVersion = "", customerId = "" } = useSelector(getUserAdditionalInfo);

  const imageUrl = `${baseUrl}/employee/${customerId}/logo?id=${pictureVersion}&variant=thumbnail`;

  const isPaymentSuccess =
    location?.pathname === CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE ||
    location?.pathname === CUSTOMER_ONBOARDING_PAYMENT_SUCCESS_ROUTE;

  const isCreateMeetingStarted = location?.pathname === CUSTOMER_ONBOARDING_CALENDLY_MEETING_ROUTE;

  useEffect(() => {
    if (userAdditionalInfo && userAdditionalInfo.status === "churned") {
      localStorage.clear();
      navigate(LOGIN_ROUTE, {
        state: {
          onboardingError: VALIDATE_TOKEN_CASES.INACTIVE_USER,
          errorMessage: "Account is not active, please contact support!",
        },
      });
    }
  }, [userAdditionalInfo, navigate]);

  return (
    <div className="onboarding-layout">
      <div className="onboarding-layout-sidebar">
        <HeaderLogo />
        <Sidebar
          isPaymentSuccess={isPaymentSuccess}
          isCreateMeetingStarted={isCreateMeetingStarted}
          isMeetingCreated={isMeetingCreated}
        />
      </div>
      <div className="onboarding-layout-content-container">
        <div className="onboarding-layout-header">
          <div className="onboarding-layout-header-logo">
            <HeaderLogo />
          </div>
          <div className="onboarding-layout-content-header">
            <AvatarMenu fullName={name} imageUrl={imageUrl} email={email} />
          </div>
        </div>
        <p className="onboarding-layout-content-title">{title}</p>
        <div className="onboarding-layout-content-sidebar">
          <Sidebar
            isPaymentSuccess={isPaymentSuccess}
            isCreateMeetingStarted={isCreateMeetingStarted}
            isMeetingCreated={isMeetingCreated}
          />
        </div>
        <div className={contentClass}>{children}</div>
      </div>
    </div>
  );
}

export default Layout;
