import React from "react";

import { Radio, FormControlLabel, Box, createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";

import "./radiobutton.scss";

const useStyles = makeStyles(() =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
  })
);

function RadioButton({ label, checked, onChange, value, name, withContainer = false, disabled = false }) {
  const classes = useStyles();

  const radioControl = (
    <FormControlLabel
      control={<Radio disabled={disabled} checked={checked} onChange={onChange} value={value} name={name} />}
      label={label}
      className={classes.smallRadioButton}
    />
  );

  const handleContainerClick = () => {
    if (!checked) {
      onChange({ target: { value, name } });
    }
  };

  return withContainer ? (
    <Box
      onClick={handleContainerClick}
      className={`radio-button__container ${checked ? "radio-button__container-checked" : ""} ${disabled ? "radio-button__container-disabled" : ""}`}
    >
      {radioControl}
    </Box>
  ) : (
    <Box className="radio-button__wrapper">{radioControl}</Box>
  );
}

export default RadioButton;
