import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SelectedFile from "./SelectedFile";

import "./drag-drop.scss";

function DragAndDrop({
  supportedFormats,
  maxFileSize,
  helperText,
  isMultiFileSupported,
  name,
  loading,
  onFileSelect,
  files = [],
  setFiles = () => {},
  resetUploadedFiles,
  isFileUploaded = false,
  maximumFilesCount,
  removeLoadingWhenCancelledUpload = () => {},
}) {
  const { control } = useFormContext() || {};
  const [hover, setHover] = useState(false);
  const [unsupportedFileMessage, setUnsupportedFileMessage] = useState("");
  const fileInputRef = useRef(null);

  const SUPPORTED_FORMATS = supportedFormats;

  const handleDragOver = (event) => {
    event.preventDefault();
    setHover(true);
  };

  const handleDragLeave = () => {
    setHover(false);
  };

  const uploadFile = (fileObj) => {
    setFiles((prevFiles) =>
      prevFiles.map((f) =>
        f.id === fileObj.id
          ? {
              ...f,
              status: "uploading",
            }
          : f
      )
    );
  };

  const addFiles = (newFiles) => {
    const currentFileCount = files.length;
    const newFileCount = newFiles.length;

    if (isMultiFileSupported && currentFileCount + newFileCount > maximumFilesCount) {
      setUnsupportedFileMessage(`Max ${maximumFilesCount} files limit reached`);
      return;
    }
    setUnsupportedFileMessage("");

    const fileArray = Array.from(newFiles).map((file) => {
      let error = null;
      let unsupported = false;

      if (!SUPPORTED_FORMATS.includes(file.type)) {
        error = "Unsupported file type";
        unsupported = true;
      } else if (file.size > maxFileSize) {
        error = "File too large";
      }

      return {
        file,
        id: Math.random(),
        fileName: file.name,
        size: file.size,
        error,
        unsupported,
        status: error ? "failed" : "loading",
      };
    });

    if (!isMultiFileSupported) {
      setUnsupportedFileMessage(fileArray.some((fileObj) => fileObj.unsupported) ? "Unsupported file type" : "");
    }

    setFiles((prevFiles) => [...prevFiles, ...fileArray]);
    const validFiles = fileArray.filter((fileObj) => !fileObj.error);

    if (onFileSelect && validFiles.length > 0) {
      onFileSelect(validFiles);
    }

    fileArray.forEach((fileObj) => {
      if (!fileObj.error) {
        uploadFile(fileObj);
      }
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setHover(false);
    const droppedFiles = event.dataTransfer.files;
    addFiles(droppedFiles);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    addFiles(selectedFiles);

    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeFile = (id) => {
    const updatedFiles = files.filter((file) => file.id !== id);

    const hasUnsupportedFiles = updatedFiles.some((file) => file.error === "Unsupported file type");

    setFiles(updatedFiles);

    if (!isMultiFileSupported) setUnsupportedFileMessage(hasUnsupportedFiles ? "Unsupported file type" : "");

    if (isMultiFileSupported && updatedFiles.length < maximumFilesCount) {
      setUnsupportedFileMessage("");
    }

    removeLoadingWhenCancelledUpload?.();
  };

  useEffect(() => {
    if (resetUploadedFiles) setFiles([]);
  }, [resetUploadedFiles]);

  const renderDragDropComponent = () => (
    <div className="uploader-container">
      {isMultiFileSupported || files.length === 0 ? (
        <div
          className={`uploader-drop-area ${hover ? "hover" : ""} ${unsupportedFileMessage ? "uploader-drop-area-failure" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple={isMultiFileSupported}
            name={name}
            data-testid="file-input"
          />
          <div className="uploader-content">
            <div className="uploader-icon">
              <UploadFileIcon />
            </div>
            <div className="uploader-text">
              <button type="button" className="upload-button" onClick={(e) => e.preventDefault()}>
                Click to upload
              </button>{" "}
              <span className="para-body-l-regular">or drag and drop</span>
            </div>
            <div className="uploader-subtext">{unsupportedFileMessage || helperText}</div>
          </div>
        </div>
      ) : null}
      <SelectedFile
        files={files}
        loading={loading}
        onFileSelect={onFileSelect}
        showGreenTick={isFileUploaded}
        isMultiFileSupported={isMultiFileSupported}
        onFileDelete={removeFile}
      />
    </div>
  );

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => renderDragDropComponent(field, error)}
      />
    );
  }

  return renderDragDropComponent({}, null);
}

DragAndDrop.propTypes = {
  supportedFormats: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
  helperText: PropTypes.string,
  isMultiFileSupported: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFileSelect: PropTypes.func,
};

DragAndDrop.defaultProps = {
  supportedFormats: ["image/svg+xml", "image/png", "image/jpeg", "image/gif"],
  maxFileSize: 100 * 1024,
  helperText: "SVG, PNG, JPG or GIF (max. 3MB)",
  isMultiFileSupported: false,
  onFileSelect: null,
};

export default DragAndDrop;
