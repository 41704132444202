import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import Button from "../../Button/Button";
import GetInTouchModal from "./GetInTouchModal";
import PageLoader from "components/common/PageLoader";

import FailIcon from "assets/images/fail.png";
import SuccessIcon from "assets/images/tick.png";
import MailIcon from "assets/images/mail.svg";
import UserIcon from "assets/images/user.svg";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";

import useStyles from "./GetInTouchStyles";

function GetInTouchForm() {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const [contactFormData, setContactFormData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalResultImage, setModalResultImage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const handleFormDataChange = (event) => {
    setContactFormData(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postRequest(
        `${baseUrl}/customer/${loggedInUser?.customerId}/getInTouch`,
        JSON.stringify({
          message: contactFormData,
          email: loggedInUser?.email,
          username: loggedInUser?.name,
        }),
        true,
        "POST"
      );

      setIsLoading(false);
      setShowModal(true);
      setModalResultImage(SuccessIcon);
      setModalTitle("Your request was shared with our team");
      setModalSubtitle("We will reach out to you directly with in 24 hours");
    } catch (error) {
      setIsLoading(false);
      setShowModal(true);
      setModalResultImage(FailIcon);
      setModalTitle("We were unable to share your request with the team");
      setModalSubtitle("Please try again in a few minutes");
    }
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <GetInTouchModal open={showModal} imageSrc={modalResultImage} title={modalTitle} subtitle={modalSubtitle} />
      <div
        style={{
          width: 752,
          height: 112,
          background: "linear-gradient(234deg, #FFEFD3 0%, var(--color-primitives-primary-100) 32%, #B4FFF2 100%)",
          borderRadius: 12,
          display: "grid",
          alignItems: "center",
          fontFamily: "Poppins-Medium",
          paddingLeft: "16px",
        }}
      >
        We will reach out to you with in 24 hours <br /> to discuss your needs in detail.
      </div>

      <Box display="flex" justifyContent="space-between" className={classes.nameAndEmailContainer}>
        <Box className={classes.section}>
          <Typography variant="h2">Username</Typography>
          <Box display="flex" alignItems="center" className={classes.iconsContainer}>
            <img src={UserIcon} alt="Email Icon" className={classes.icon} />
            <Typography variant="body1">{loggedInUser?.name}</Typography>
          </Box>
        </Box>
        <Box className={classes.section}>
          <Typography variant="h2">Email</Typography>
          <Box display="flex" alignItems="center" className={classes.iconsContainer}>
            <img src={MailIcon} alt="Name Icon" className={classes.icon} />
            <Typography variant="body1">{loggedInUser?.email}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.form}>
        <Typography variant="h2">Explain us what you are looking for (Optional)</Typography>

        <textarea
          className={classes.contactTextarea}
          placeholder="For example, I need 2-3 employees who can......"
          value={contactFormData}
          onChange={handleFormDataChange}
        />

        <Box display="flex" className={classes.actionButtons}>
          <Button
            onClick={() => navigate("/main/dashboard")}
            variant="outlined"
            size="lg"
            className={classes.actionButton}
            style={{ color: "var(--color-primitives-primary-600)", marginRight: "1rem" }}
          >
            Cancel
          </Button>

          <Button variant="primary" size="lg" disableElevation className={classes.actionButton} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default GetInTouchForm;
