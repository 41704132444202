import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Switch } from "@mui/material";

import CustomModal from "components/common/Modal";
import Select from "components/common/Select";
import Text from "components/common/Text";
import CustomDatePicker from "components/common/DatePicker";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";
import SearchableAutoComplete from "components/common/AutoComplete";

import { GENDERS, MARITAL_STATUS } from "constants";

import EditTalentInfoSchema from "validations/Admin/EditTalentInfoSchema";
import EmployeePersonalInfoSchema from "validations/BambooHRTabs/EmployeePersonalInfoSchema";

import { getEmployeeSuccessManagersList, updateEmployeePersonalDetails } from "services/Admin/Dashboard";
import { updateEmployeePersonalInfo } from "services/BambooHrTabs/about";

import { showSnackbar } from "store/actions/Utility";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import { getNationalIDHelperText } from "util/utils";

import "./EditPersonalInfoModal.scss";

function EditPersonalInfoModal({
  open,
  closeModal,
  initialPersonalDataWorkforce,
  type = "admin",
  fetchEmployeeDetailsByIDV2,
}) {
  const dispatch = useDispatch();
  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const countries = countriesList?.map(({ countryCode }) => ({
    value: countryCode,
    label: countryCode,
  }));

  const values = {
    employeeSuccessManager: `${initialPersonalDataWorkforce?.hrpoc?.firstName} ${initialPersonalDataWorkforce?.hrpoc?.lastName}`,
    ...initialPersonalDataWorkforce,
  };

  const methods = useForm({
    resolver: yupResolver(type === "admin" ? EditTalentInfoSchema : EmployeePersonalInfoSchema),
    mode: "onChange",
    values,
  });

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid },
  } = methods;

  const selectedCountry = watch("country");
  const isEmployeeAllowedForPayoneer = watch("allowedForPayoneer");

  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [successManagersList, setSuccessManagersList] = useState([]);

  useEffect(() => setIsUpdatingData(false), [open]);

  // eslint-disable-next-line consistent-return
  const onSubmit = async (data) => {
    setIsUpdatingData(true);
    if (type === "employee") {
      try {
        const employeeId = initialPersonalDataWorkforce?.employeeId;
        await updateEmployeePersonalInfo(employeeId, {
          gender: data.gender,
          dateOfBirth: moment(data.dateOfBirth).format("YYYY-MM-DD"),
          maritalStatus: data.maritalStatus,
        });
        dispatch(showSnackbar({ type: AlertType.success, message: "Profile updated successfully", dispatch }));
        closeModal();
      } catch (error) {
        return dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
      }
    }

    if (type === "admin") {
      const {
        nationalID: cnic,
        dateOfBirth,
        firstName,
        middleName,
        preferredName,
        lastName,
        jobTitle,
        employeeSuccessManager,
        country,
        gender,
        maritalStatus,
        allowedForPayoneer,
      } = data;

      const res = await updateEmployeePersonalDetails({
        firstName,
        lastName,
        country,
        cnic_dni_cex: cnic,
        gender,
        maritalStatus,
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        jobTitle,
        allowedForPayoneer,
        edgeHREmployeeID:
          successManagersList.find((manager) => employeeSuccessManager == manager?.label)?.userId || null,
        employeeId: initialPersonalDataWorkforce?.employeeId,
        middleName: middleName || null,
        preferredName: preferredName || null,
      });
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

      if (res.payload.error) {
        setIsUpdatingData(false);
        return dispatch(
          showSnackbar({
            type: AlertType.error,
            message: res.payload?.error?.[0]?.msg ?? "An error occurred",
            dispatch,
          })
        );
      }

      fetchEmployeeDetailsByIDV2();

      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: initialPersonalDataWorkforce?.employeeId,
          request: `employee/${initialPersonalDataWorkforce?.employeeId}`,
        })
      );
      dispatch(showSnackbar({ type: AlertType.success, message: "Personal information updated", dispatch }));

      setIsUpdatingData(false);
      closeModal();
    }
  };

  const mapSuccessManagersList = (managersList = []) =>
    managersList
      ?.filter((i) => i)
      .map(({ firstName, lastName, userId }) => ({
        label: `${firstName} ${lastName}`,
        value: `${firstName} ${lastName}`,
        userId,
      }));

  const fetchEmployeeSuccessManagersList = async () => {
    const res = await getEmployeeSuccessManagersList();
    setSuccessManagersList(mapSuccessManagersList(res.rows));
  };

  useEffect(() => {
    if (type === "admin") {
      fetchEmployeeSuccessManagersList();
    }
  }, []);

  return (
    <CustomModal open={open} title="Personal" width="700px" onClose={closeModal}>
      <FormProvider {...methods}>
        <form
          className="mt-15"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1">
              <div className="flex gap-1">
                <Text label="First name" name="firstName" placeholder="e.g. John" disabled={type === "employee"} />
                <Text
                  label="Middle name"
                  isOptional
                  name="middleName"
                  placeholder="e.g. Doe"
                  disabled={type === "employee"}
                />
              </div>
              <div className="flex gap-1">
                <Text label="Last name " name="lastName" placeholder="e.g. Smith" disabled={type === "employee"} />
                <Text
                  label="Preferred name"
                  disabled={type === "employee"}
                  isOptional
                  name="preferredName"
                  placeholder="e.g. John"
                />
              </div>
            </div>

            <div className="flex gap-1">
              <Text
                label="Job title"
                name="jobTitle"
                placeholder="e.g. Client associate"
                disabled={type === "employee"}
              />
              <SearchableAutoComplete
                label="Employee success manager"
                name="employeeSuccessManager"
                isOptional
                placeholder="Search by name"
                options={
                  type === "employee"
                    ? mapSuccessManagersList([initialPersonalDataWorkforce?.hrpoc])
                    : successManagersList
                }
                disabled={type === "employee"}
              />
            </div>

            <div className="flex gap-1">
              <SearchableAutoComplete
                label="Country"
                name="country"
                placeholder="Select country"
                options={countries}
                disabled={type === "employee"}
              />
              <Text
                label="National ID"
                name="nationalID"
                helperTextWithoutError={getNationalIDHelperText(selectedCountry)}
                placeholder="CNIC, DNI, etc."
                disabled={type === "employee"}
              />
            </div>

            <div className="flex gap-1">
              <Select label="Gender" name="gender" placeholder="Select" options={GENDERS} />
              <div className="mt-025 w-full">
                <CustomDatePicker name="dateOfBirth" label="Date of birth" />
              </div>
              <Select
                label="Marital status"
                isOptional
                name="maritalStatus"
                placeholder="Select"
                options={MARITAL_STATUS}
              />
            </div>

            {selectedCountry === "Pakistan" && type === "admin" && (
              <div className="flex gap-1">
                <div className="w-50p">
                  <div className="para-body-m-medium text-headings-secondary">Edge Wallet</div>
                  <div className="flex justify-between">
                    <span className="para-body-l-regular align-self-center">
                      {isEmployeeAllowedForPayoneer ? "Enabled" : "Disabled"}
                    </span>
                    <span>
                      <Switch
                        {...register("allowedForPayoneer")}
                        defaultChecked={initialPersonalDataWorkforce?.allowedForPayoneer}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-action-buttons">
            <Button onClick={closeModal} label="Cancel" variant="outlined" size="large" />
            <Button
              type="submit"
              isDisabled={!isValid}
              isLoading={isUpdatingData}
              label="Save"
              variant="contained"
              size="large"
            />
          </div>
        </form>
      </FormProvider>
    </CustomModal>
  );
}

export default EditPersonalInfoModal;
