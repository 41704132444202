/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Stack, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PendingRequests from "components/Screens/Workforce/UserDetailsV2/Timeoff/PendingLeaves";
import CustomDropdown from "components/common/Dropdown";
import PageLoader from "components/common/PageLoader";
import HistoryCard from "./HistoryCard";

import {
  getCustomerEmployeeLeaves,
  getEmployeeLeavesDataForCustomer,
  getEmployeePendingLeaves,
} from "services/useTimeoffService";

import combineUpcomingLeavesAndHolidays from "util/combineUpcomingLeavesAndHolidays";
import LeavesValidityDates from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/LeavesValidityDates";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "assets/svgs/InfoIcon";
import LeftArrow from "assets/images/left-arrow.svg";
import CalendarIcon from "assets/svgs/CalendarIcon";
import LeftArror from "assets/images/left-Arrow-filled.svg";
import { ReactComponent as BeachHolidayIcon } from "assets/images/beach-holiday.svg";
import RightArror from "assets/images/right-Arrow-filled.svg";

import "./Timeoff.css";

function TimeoffTab() {
  const navigate = useNavigate();

  const [leaveRequests, setLeavesRequests] = useState({});
  const [historyDropdownLeaves, setHistoryDropdownLeaves] = useState([]);
  const [employeeLeavesData, setEmployeeLeavesData] = useState([]);
  const [historyLeaveType, setHistoryLeaveType] = useState("scheduled");
  const [loading, setLoading] = useState(false);
  const [leavesAndHolidays, setLeavesAndHolidays] = useState([]);
  const [upcomingLeaves, setUpcomingLeaves] = useState([]);
  const [currentShownLeaveCard, setCurrentShownLeaveCard] = useState(0);
  const allHolidays = useSelector((state) => state?.Holidays?.allHolidays);

  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: "#292A3D",
      color: "#F5F5F5",
      borderRadius: "8px",
      fontSize: "12px",
      width: "70%",
      padding: "9px",
    },
  }));

  const fetchEmployeeLeavesDataForCustomer = async () => {
    const res = await getEmployeeLeavesDataForCustomer(location.pathname.split("/")[3]);
    setEmployeeLeavesData(res?.payload?.data);
  };

  const fetchEmployeePendingLeaves = async () => {
    const res = await getEmployeePendingLeaves(location.pathname.split("/")?.[3]);
    setLeavesRequests(res?.payload?.data || {});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchEmployeeLeavesDataForCustomer();
        await fetchEmployeePendingLeaves();
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updateHistoryDropdown = async () => {
      try {
        setLoading(true);
        const { pending, ...restLeaves } = leaveRequests;
        setHistoryDropdownLeaves(
          Object.keys(restLeaves).map((leave) => ({
            key: leave,
            label: leave,
          }))
        );
      } catch (error) {
        console.error("Failed to update history dropdown:", error);
      } finally {
        setLoading(false);
      }
    };

    if (Object.keys(leaveRequests).length > 0) {
      updateHistoryDropdown();
    }
  }, [leaveRequests]);

  const fetchUpcomingLeavesForEmployee = async () => {
    const res = await getCustomerEmployeeLeaves(location.pathname?.split("/")?.[3]);
    setUpcomingLeaves(res?.payload?.data);
  };

  useEffect(() => {
    fetchUpcomingLeavesForEmployee();
  }, []);

  useEffect(() => {
    setLeavesAndHolidays(combineUpcomingLeavesAndHolidays(upcomingLeaves, allHolidays?.data));
  }, [upcomingLeaves, allHolidays]);

  const slicedLeavesAndHolidays = leavesAndHolidays.slice(currentShownLeaveCard, currentShownLeaveCard + 2);
  const onHistoryCLick = (leave) =>
    navigate("timeoff", {
      state: {
        leaveData: leaveRequests?.[historyLeaveType],
        currentLeave: leave,
      },
    });

  const classes = useStyles();

  return (
    <div>
      {loading && <PageLoader />}
      <Stack direction="row" justifyContent="space-between" sx={{ mb: "8px" }}>
        <p className="title-md">Leaves taken</p>

        {employeeLeavesData?.length && employeeLeavesData?.[0]?.startDate && employeeLeavesData?.[0]?.endDate && (
          <LeavesValidityDates
            startDate={employeeLeavesData?.[0]?.startDate}
            endDate={employeeLeavesData?.[0]?.endDate}
          />
        )}
      </Stack>

      <div className="timeoff-taken-tab">
        <div
          className={`leave-count w-25p ${
            employeeLeavesData?.[0]?.scheduled + employeeLeavesData?.[0]?.pending > 0 && "align-self-center"
          }`}
        >
          {employeeLeavesData?.length ? (
            <div className="leave-count-flex-row">
              <div className="leave-count-remaining">{employeeLeavesData?.[0]?.taken}</div>
              <div className="leave-count-total">/{employeeLeavesData?.[0]?.noOfLeaves}</div>
            </div>
          ) : null}
          <div className="leave-card-type">{employeeLeavesData?.[0]?.leaveType}</div>
          {employeeLeavesData?.[0]?.scheduled + employeeLeavesData?.[0]?.pending > 0 && (
            <div className="leave-count-scheduled-pending">
              {`+${employeeLeavesData?.[0]?.scheduled + employeeLeavesData?.[0]?.pending} scheduled/pending`}
            </div>
          )}
        </div>

        <Divider flexItem orientation="vertical" />

        <div
          className={`leave-count w-25p ${
            employeeLeavesData?.[1]?.scheduled + employeeLeavesData?.[1]?.pending > 0 && "align-self-center"
          }`}
        >
          {employeeLeavesData?.length ? (
            <div className="leave-count-flex-row">
              <div className="leave-count-remaining">{employeeLeavesData?.[1]?.taken}</div>
              <div className="leave-count-total">
                {employeeLeavesData?.[1]?.noOfLeaves > 0 ? `/${employeeLeavesData?.[1]?.noOfLeaves}` : ""}
              </div>
            </div>
          ) : null}
          <div className="leave-card-type">{employeeLeavesData?.[1]?.leaveType}</div>
          {employeeLeavesData?.[1]?.scheduled + employeeLeavesData?.[1]?.pending > 0 && (
            <div className="leave-count-scheduled-pending">
              {`+${employeeLeavesData?.[1]?.scheduled + employeeLeavesData?.[1]?.pending} scheduled/pending`}
            </div>
          )}
        </div>

        <Divider flexItem orientation="vertical" />

        <div
          className={`leave-count w-25p ${
            employeeLeavesData?.[2]?.scheduled + employeeLeavesData?.[2]?.pending > 0 && "align-self-center"
          }`}
        >
          {employeeLeavesData?.length ? (
            <div className="leave-count-flex-row">
              <div className="leave-count-remaining">{employeeLeavesData?.[2]?.taken}</div>
              <div className="leave-count-total">
                {employeeLeavesData?.[2]?.noOfLeaves > 0 ? `/${employeeLeavesData?.[2]?.noOfLeaves}` : ""}
              </div>
            </div>
          ) : null}
          <div className="leave-card-type">{employeeLeavesData?.[2]?.leaveType}</div>
          {employeeLeavesData?.[2]?.scheduled + employeeLeavesData?.[2]?.pending > 0 && (
            <div className="leave-count-scheduled-pending">
              {`+${employeeLeavesData?.[2]?.scheduled + employeeLeavesData?.[2]?.pending} scheduled/pending`}
            </div>
          )}
        </div>

        <Divider flexItem orientation="vertical" />

        {employeeLeavesData?.[3]?.leaveType !== "Swapped" && (
          <div
            className={`leave-count w-25p ${
              employeeLeavesData?.[3]?.scheduled + employeeLeavesData?.[3]?.pending > 0 && "align-self-center"
            }`}
          >
            {employeeLeavesData?.length ? (
              <div className="leave-count-flex-row">
                <div className="leave-count-remaining">{employeeLeavesData?.[3]?.taken}</div>
                {employeeLeavesData?.[3]?.isFinite && (
                  <div className="leave-count-total">/{employeeLeavesData?.[3]?.noOfLeaves}</div>
                )}
              </div>
            ) : null}
            {employeeLeavesData?.[3]?.leaveType && (
              <div className="leave-type-container">
                <div className="leave-card-type">{employeeLeavesData?.[3]?.leaveType}</div>
                <Tooltip
                  classes={{ tooltip: classes.customTooltip }}
                  title="For each unpaid leave your employee takes, we will reimburse. Contact accounts for more details"
                  cursor
                  placement="bottom"
                >
                  <div style={{ cursor: "pointer" }}>
                    <InfoIcon fill="#44146c" size="18" />
                  </div>
                </Tooltip>
              </div>
            )}

            {employeeLeavesData?.[3]?.visible && (
              <div className="leave-count-scheduled-pending">
                {`+${employeeLeavesData?.[3]?.pending} scheduled/pending`}
              </div>
            )}
          </div>
        )}
      </div>

      <p className="title-md mt-2 mb-1">Upcoming Leaves and holidays</p>
      <div className="timeoff-taken-tab border-none bg-primary-95 flex gap-1 p-1">
        <img
          src={LeftArror}
          style={{
            filter: currentShownLeaveCard === 0 ? "grayscale(1)" : "",
            cursor: currentShownLeaveCard === 0 ? "not-allowed" : "pointer",
          }}
          onClick={() => {
            if (currentShownLeaveCard !== 0) setCurrentShownLeaveCard(currentShownLeaveCard - 1);
          }}
          alt="left arrow"
        />
        {slicedLeavesAndHolidays?.map((leave) => (
          <Grid
            className="flex"
            sx={{
              borderRadius: "12px",
              background: "#FFF",
              boxShadow: "0px 1px 6px 0px rgba(2, 0, 110, 0.08), 0px 1px 1px 0px rgba(2, 0, 110, 0.06)",
              width: "45%",
              padding: "0.75rem 0.5rem",
            }}
          >
            <span className="p-1 h-max-con  rounded-lg bg-primary-95 flex">
              {!leave?.isHoliday ? (
                <BeachHolidayIcon />
              ) : (
                <CalendarIcon style={{ transform: "scale(1.5)" }} fill="#44146c" />
              )}
            </span>
            <div className="flex flex-col">
              <div className="pl-1">
                <div className="pending-leave-reason">{leave?.label} </div>

                <div className="pending-leave-duration ">
                  <div className="pending-leave-date">{moment.utc(leave?.startDate).format("MMM DD, YYYY")}</div>
                  {leave?.endDate ? (
                    <>
                      <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
                      <div className="pending-leave-date"> {moment(leave?.endDate).format("MMM DD, YYYY")}</div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className=" pl-1 pt-05 flex items-center">
                <div className="timeoff-used-label"> {leave?.isLeave ? "Total time-off used" : "Total Days off"}</div>
                <div className="timeoff-used-value">
                  {leave?.totalTimeOff} {leave?.totalTimeOff == 1 ? "day" : "days"}
                </div>
              </div>
            </div>
          </Grid>
        ))}

        <img
          style={{
            filter: currentShownLeaveCard === leavesAndHolidays.length - 1 ? "grayscale(1)" : "",
            cursor: currentShownLeaveCard === leavesAndHolidays.length - 1 ? "not-allowed" : "pointer",
          }}
          src={RightArror}
          onClick={() => {
            if (currentShownLeaveCard !== leavesAndHolidays.length - 1)
              setCurrentShownLeaveCard(currentShownLeaveCard + 1);
          }}
          alt="left arrow"
        />
      </div>

      <span style={{ overflowY: "scroll", maxHeight: "73vh", display: "block" }}>
        <div className="mt-15">
          <PendingRequests
            pendingLeaveRequests={leaveRequests?.pending}
            fetchEmployeePendingLeaves={fetchEmployeePendingLeaves}
          />
        </div>

        <div className="mt-15 timeoff-history-container">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <p>History</p>
            </AccordionSummary>
            <AccordionDetails>
              <CustomDropdown
                options={historyDropdownLeaves}
                onSelect={(key) => {
                  setHistoryLeaveType(key);
                }}
                buttonLabel={historyDropdownLeaves.find((leave) => leave.label === historyLeaveType)?.label}
              />
              <div className="mt-1 flex flex-col gap-05">
                {leaveRequests?.[historyLeaveType]?.length ? (
                  <div>
                    {leaveRequests?.[historyLeaveType]?.map((leave) => (
                      <HistoryCard data={leave} onClick={() => onHistoryCLick(leave)} />
                    ))}
                  </div>
                ) : (
                  <p className="text-center">No data available</p>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </span>
    </div>
  );
}

export default TimeoffTab;
