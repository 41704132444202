import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { IconButton, Link } from "@mui/material";

import Button from "components/common/Button";

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import "./top-header.scss";

function TopHeader({
  breadcrumbs,
  primaryTitle = "Home",
  backIcon = "",
  primaryBtn,
  secondaryBtn,
  title,
  subtext,
  linkName = "",
  link = "#",
  primaryLink = "/main/dashboard",
  primaryBtnClick,
  secondaryBtnClick,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="top-header-wrapper">
      <div className="flex flex-col space-y-025">
        {breadcrumbs && (
          <div className="flex items-center">
            <Link className="header-link" component={RouterLink} to={primaryLink}>
              <p className="header-link para-body-s-regular">{primaryTitle}</p>
            </Link>
            <div className="header-divider-wrapper">
              <p className="header-link-divider px-05">/</p>
            </div>
            <Link
              component={RouterLink}
              to={link}
              className="header-link-secondary"
              aria-current={link === location.pathname ? "page" : undefined}
            >
              <p className="header-link-secondary para-body-s-regular">{linkName}</p>
            </Link>
          </div>
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-05 cursor-pointer">
            {backIcon && (
              <IconButton size="small" onClick={() => navigate(backIcon)} className="ml-05">
                <ArrowBackOutlinedIcon className="black-icon" />
              </IconButton>
            )}
            <div>
              <p className="heading-h3-semibold">{title}</p>
              {subtext && <p className="titlebar-subtext">{subtext}</p>}
            </div>
          </div>
          <div className="flex items-center gap-075">
            {secondaryBtn && (
              <Button
                variant="outlined"
                label={secondaryBtn}
                onClick={secondaryBtnClick}
                startIcon={<EditOutlinedIcon className="button-secondary-icon" />}
              />
            )}
            {primaryBtn && (
              <Button
                variant="contained"
                label={primaryBtn}
                onClick={primaryBtnClick}
                startIcon={<EditOutlinedIcon className="button-primary-icon" />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
