import React, { useState } from "react";
import SuprSendInbox from "@suprsend/react-inbox";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import "./Notifications.scss";

function CustomBellComponent() {
  return <NotificationsNoneIcon />;
}

function Notifications({ userId, subscriberId }) {
  const [inboxError, setInboxError] = useState(false);
  const WORKSPACE_KEY = process.env.REACT_APP_SUPR_SEND_WORKSPACE_KEY;

  const handleError = () => {
    setInboxError(true);
  };

  const customTheme = {
    bell: {
      iconFill: "var(--color-brand-primary-grey-400)",
    },
    badge: {
      backgroundColor: "var(--color-primitives-danger-600)",
      color: "var(--color-primitives-white-white)",
    },
    header: {
      container: {
        backgroundColor: "var(--color-surface-primary)",
        borderBottom: "1px solid var(--color-border-primary)",
        padding: "16px",
        paddingBottom: "0px",
      },
      headerText: {
        color: "var(--color-text-headings-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-lg)",
        fontWeight: "var(--font-weight-semibold)",
      },
      markAllReadText: {
        color: "var(--color-primitives-primary-600)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-md)",
        fontWeight: "var(--font-weight-medium)",
      },
    },
    tabs: {
      color: "var(--color-primitives-primary-600)",
      unselectedColor: "var(--color-text-body-primary)",
      bottomColor: "var(--color-primitives-primary-600)",
      badgeColor: "var(--color-primitives-neutral-100)",
      badgeText: "var(--color-text-body-primary)",
    },
    notificationsContainer: {
      container: {
        backgroundColor: "var(--color-surface-primary)",
        borderColor: "var(--color-border-primary)",
        borderRadius: "var(--border-radius-md)",
        boxShadow: "0px 4px 8px 0px var(--color-elevation-default)",
      },
      noNotificationsText: {
        color: "var(--color-text-headings-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-lg)",
        fontWeight: "var(--font-weight-medium)",
      },
      noNotificationsSubtext: {
        color: "var(--color-text-body-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-md)",
      },
      loader: { color: "var(--color-primitives-primary-600)" },
    },
    notification: {
      container: {
        borderBottom: "1px solid var(--color-border-primary)",
        readBackgroundColor: "var(--color-surface-primary)",
        unreadBackgroundColor: "var(--color-surface-secondary)",
        hoverBackgroundColor: "var(--color-surface-primary-hover)",
      },
      headerText: {
        color: "var(--color-text-headings-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-md)",
        fontWeight: "var(--font-weight-medium)",
      },
      bodyText: {
        color: "var(--color-text-body-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-md)",
        blockquoteColor: "var(--color-border-primary)",
        tableBorderColor: "var(--color-border-primary)",
        linkColor: "var(--color-primitives-primary-600)",
      },
      unseenDot: { backgroundColor: "var(--color-primitives-primary-600)" },
      createdOnText: {
        color: "var(--color-text-body-primary)",
        fontSize: "var(--font-size-sm)",
      },
      actions: [
        {
          container: {
            backgroundColor: "var(--color-surface-action-primary)",
            hoverBackgroundColor: "var(--color-surface-action-primary-hover)",
          },
          text: {
            color: "var(--color-text-on-action-white)",
            fontFamily: "var(--font-family-inter)",
            fontSize: "var(--font-size-sm)",
            fontWeight: "var(--font-weight-medium)",
          },
        },
        {
          container: {
            borderColor: "var(--color-border-primary)",
            backgroundColor: "var(--color-surface-primary)",
            hoverBackgroundColor: "var(--color-surface-primary-hover)",
          },
          text: {
            color: "var(--color-text-body-primary)",
            fontFamily: "var(--font-family-inter)",
            fontSize: "var(--font-size-sm)",
            fontWeight: "var(--font-weight-medium)",
          },
        },
      ],
      expiresText: {
        backgroundColor: "var(--color-primitives-neutral-100)",
        color: "var(--color-text-body-primary)",
        expiringBackgroundColor: "var(--color-primitives-danger-50)",
        expiringColor: "var(--color-text-danger)",
      },
    },
    toast: {
      container: {
        backgroundColor: "var(--color-surface-primary)",
        borderColor: "var(--color-border-primary)",
        borderRadius: "var(--border-radius-md)",
        boxShadow: "var(--box-shadow-md)",
      },
      headerText: {
        color: "var(--color-text-headings-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-md)",
        fontWeight: "var(--font-weight-medium)",
      },
      bodyText: {
        color: "var(--color-text-body-primary)",
        fontFamily: "var(--font-family-inter)",
        fontSize: "var(--font-size-sm)",
        blockquoteColor: "var(--color-border-primary)",
        tableBorderColor: "var(--color-border-primary)",
        linkColor: "var(--color-primitives-primary-600)",
      },
    },
  };

  return (
    <div className="notifications-container">
      {!inboxError && userId && (
        <SuprSendInbox
          workspaceKey={WORKSPACE_KEY}
          distinctId={userId}
          subscriberId={subscriberId}
          onError={handleError}
          theme={customTheme}
          popperPosition="bottom"
          hideToast
          toastProps={{
            position: "bottom-right",
            duration: 5000,
            limit: 3,
          }}
          bellComponent={CustomBellComponent}
        />
      )}
    </div>
  );
}

export default Notifications;
