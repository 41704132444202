import React, { useEffect, useState } from "react";

import {
  getProfileImage,
  getSalaryWithoutBonusAndIncrement,
  getSalaryWithoutBouns,
  getValue,
  currencyFormat,
} from "components/Screens/CommonUtils";

import "./PayrollHistoryEmployeeBreakdown.css";
import { s3ImagesBaseURL } from "util/APIBaseUrl";

function PayrollHistoryEmployeeTable({
  employee: {
    employeeName,
    jobTitle,
    costToCompany,
    incrementDetails: { incrementAmount, bonus, description },
    pictureVersion,
    employeeId,
  },
}) {
  const [imageSrc, setImageSrc] = useState(`${s3ImagesBaseURL}/placeholder-avatar.svg`);

  useEffect(() => {
    if (pictureVersion) {
      getProfileImage(employeeId, pictureVersion).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(`${s3ImagesBaseURL}/placeholder-avatar.svg`);
    }
  }, [employeeId, pictureVersion]);

  return (
    <>
      <div className="pd-breakdown-table-name-container-1">
        <img src={imageSrc[employeeId] || `${s3ImagesBaseURL}/placeholder-avatar.svg`} alt="display-image" />

        <div className="pd-breakdown-table-name">
          <p className="pd-breakdown-table-fullname">{employeeName}</p>

          <p className="pd-breakdown-table-designation">{jobTitle}</p>
        </div>
      </div>

      <div className="pd-breakdown-table-name-container-2">
        <p className="pd-breakdown-table-amount">
          $ {currencyFormat(getValue(getSalaryWithoutBouns(costToCompany, bonus)))}
        </p>
        <p className="pd-employee-subtext">
          ${currencyFormat(getValue(getSalaryWithoutBonusAndIncrement(costToCompany, incrementAmount, bonus)))}+ $
          {incrementAmount}
        </p>
      </div>

      <div className="pd-breakdown-table-name-container-2">
        <p className="pd-breakdown-table-amount">$ {currencyFormat(getValue(bonus))}</p>
        <p className="pd-employee-subtext">{getValue(description)}</p>
      </div>

      <div className="pd-breakdown-table-name-container-4">
        <p className="pd-breakdown-table-amount">$ {currencyFormat(getValue(costToCompany))}</p>
      </div>
    </>
  );
}

export default PayrollHistoryEmployeeTable;
