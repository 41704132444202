import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardContent, CardMedia, Grid, Typography, Chip } from "@mui/material";

import PayoneerCreateAccountSkeleton from "./PayoneerCreateAccountSkeleton";

import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import { AlertType } from "components/common/Snackbar";
import { getPayoneerRegistrationLink } from "services/Payoneer";
import { showSnackbar } from "store/actions/Utility";

import payoneerLogoNew from "assets/images/payoneer/Payoneer_new_logo.svg";

import { s3ImagesBaseURL } from "util/APIBaseUrl";

import "../payoneer.css";

function PayoneerCreateAccount() {
  const [loading, setLoading] = useState(false);
  const [registrationLink, setRegistrationLink] = useState("");
  const dispatch = useDispatch();

  function showRegistrationLinkError() {
    dispatch(
      showSnackbar({
        type: AlertType.error,
        message: "Error while creating Payoneer registration link. Please contact support.",
        dispatch,
      })
    );
  }

  function handleCreateBtn() {
    if (!registrationLink) {
      showRegistrationLinkError();
      return;
    }
    window.location.href = registrationLink;
  }

  async function payoneerRegistrationLink() {
    try {
      setLoading(true);
      const payoneerRegLink = await getPayoneerRegistrationLink();
      setRegistrationLink(payoneerRegLink);
    } catch (error) {
      showRegistrationLinkError();
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    payoneerRegistrationLink();
  }, []);

  return (
    <div>
      {loading ? (
        <PayoneerCreateAccountSkeleton />
      ) : (
        <Grid container className="justify-center items-center w-100p" id="payoneer-create-account-card">
          <div className="peers-wrapper">
            <Card className="create-payoneer-account-card">
              <CardContent>
                <CardMedia
                  image={`${s3ImagesBaseURL}/edge-wallet.svg`}
                  alt="financial"
                  component="img"
                  sx={{
                    height: 153,
                    objectFit: "contain",
                  }}
                />
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "24px",
                      width: "40%",
                    }}
                  >
                    You can now receive your salary directly in the USD!
                  </Typography>
                  <Chip
                    id="payoneer-create-account-link"
                    label="Create Payoneer account"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleCreateBtn}
                  />
                  <Grid className="flex flex-row items-center justify-center text-center mt-1">
                    <div className="mr-1">
                      <EdgeLogo size="sm" state="dark" />
                    </div>

                    <CardMedia
                      image={payoneerLogoNew}
                      alt="payoneerLogo"
                      component="img"
                      sx={{
                        height: 26,
                        objectFit: "contain",
                        opacity: "100%",
                      }}
                    />
                  </Grid>
                  <Grid sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "var(--temp-color-primitives-primary-800)",
                      }}
                    >
                      What it means for you?
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "var(--temp-color-primitives-primary-800)",
                        mt: 1,
                      }}
                    >
                      Receive your payments directly in USD <br />
                      Hold your savings in USD <br />
                      Payment will be made as a remittance which means
                      <br />
                      you would incurring minimal taxes
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      )}
    </div>
  );
}

export default PayoneerCreateAccount;
