import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Avatar, Badge } from "@mui/material";

import ImageCropModal from "../../common/ImageCropperModal";

import { usePermissions } from "../../Hook";
import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";

import EditIcon from "assets/svgs/EditIcon";
import AvatarIcon from "../../Icons/AvatarIcon";

import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import "./EmployeeCover.css";

const avatarStyle = {
  height: "100px !important",
  width: "100px !important",
  position: "absolute",
  border: "0.5px solid white",
  left: "10px",
  top: "-10px",
};

function EmployeeCard(props) {
  const { pictureUrl, page, name } = props;
  const dispatch = useDispatch();
  const photoInput = React.createRef();

  const { loggedInUser } = usePermissions();

  const [loadImageFormData, setLoadImageFormData] = React.useState({
    loadFromUrl: true,
    imagePreviewUrl: null,
    imageBlob: null,
  });
  const [showImageCropperModal, setShowImageCropperModal] = React.useState(false);
  const [values, setValues] = useState({
    imagePreviewUrl: "",
    picFile: null,
  });
  const [imageSrc, setImageSrc] = React.useState({});
  const [isFileUploadError, setIsFileUploadError] = React.useState(false);

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }

    setShowImageCropperModal(false);

    const request = `employee/${loggedInUser.userId}`;
    dispatch(UserAdditionalInfo(request));

    // eslint-disable-next-line consistent-return
    return response;
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setValues({
            ...values,
            picFile: file,
            imagePreviewUrl: reader.result,
          });
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);

    setImageSrc(imageDataUrl);

    setLoadImageFormData({
      loadFromUrl: false,
    });

    setShowImageCropperModal(true);
  };

  const handleSave = (param) => {
    const formData = new FormData();
    formData?.append("file", param);

    setShowImageCropperModal(false);

    setValues({
      ...values,
      picFile: param,
    });

    postLogo({
      request: `employee/${loggedInUser?.userId}/logo`,
      formData,
    });
  };

  return (
    <div
      className="employee-card-d1"
      style={{
        background: "linear-gradient(264.95deg, var(--color-primitives-primary-200) 27.15%, #F1CEDC 101.55%)",
      }}
    >
      <ImageCropModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
      <div className="employee-card-d2">
        <input
          type="file"
          accept="image/png, image/jgp, image/jpeg"
          onChange={handleImageChange}
          ref={photoInput}
          style={{ display: "none" }}
        />

        {!page ? (
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="standard"
            badgeContent={
              <EditIcon
                color="var(--temp-color-primitives-primary-800)"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#F7F7F7",
                minWidth: "40px",
                height: "40px",
                borderRadius: "25px",
                boxShadow: "0px 4px 10px 0px #080D461F",
                position: "absolute",
                left: "65px",
                top: "30px",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              uploadProfilePic();
            }}
          >
            <AvatarIcon
              size={avatarStyle}
              source={loadImageFormData?.loadFromUrl ? pictureUrl : values.picFile}
              alt={name}
            />
          </Badge>
        ) : (
          <Avatar
            sx={{ height: "100px !important", width: "100px !important" }}
            className="employee-card-avatar"
            alt={name}
            src={pictureUrl}
          />
        )}
      </div>
      <img src={`${s3ImagesBaseURL}/globe.svg`} className="employee-details-glove-img" alt="globe" />
    </div>
  );
}

export default EmployeeCard;
