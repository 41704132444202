import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import Payroll from "./Payroll";
// TODO - need to be removed after removal of integratedContractSigning flag used in this
import PayrollRebrand from "./PayrollRebrand";
import PayrollHistory from "./PayrollHistory";
import PayrollDetails from "./PayrollDetails";

import { ResetGetPaymentDetails } from "store/actions/Payroll/GetPaymentDetails";
import { ResetGiveRaise } from "store/actions/Raises/GiveRaise";
import { ResetUserAdditionalInfo } from "store/actions/UserAdditionalInfo";

function PayrollV2() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { integratedContractSigning } = useFlags();

  const [option, setOption] = useState("Payroll");

  const selectPageHeading = (opt) => {
    if (opt == "Payroll History" || opt == "Payroll") {
      dispatch(ResetGetPaymentDetails({}));
    }
    setOption(opt);
  };

  function getPayrollComponent() {
    // TODO - need to be removed after removal of integratedContractSigning flag used in this
    if (integratedContractSigning) return <PayrollRebrand />;
    return <Payroll />;
  }

  useEffect(() => {
    if (state) selectPageHeading(state?.page);
    else selectPageHeading("Payroll");

    dispatch(ResetGiveRaise({}));
    dispatch(ResetUserAdditionalInfo({}));
  }, []);

  return (
    <Grid container direction="column" rowSpacing={3}>
      <Grid item container direction="row">
        <Grid item xs={12}>
          {option == "Payroll" && getPayrollComponent()}
          {option == "Payroll History" && <PayrollHistory selectPageHeading={selectPageHeading} />}
          {option == "Payroll Details" && <PayrollDetails />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PayrollV2;
