import { Chip } from "@mui/material";

import Button from "components/Button/Button";
import {
  convertDate,
  convertDateToUTCMonthAndYear,
  currencyFormat,
  getStatusChip,
  getValue,
} from "components/Screens/CommonUtils";
import CustomTooltip from "components/common/Tooltip";

import { InfoOutlined } from "@mui/icons-material";

import "./PayrollDetails.css";

function RenderPayrollItem({ label, value }) {
  return (
    <div className="flex justify-between pb-05">
      <p className="heading-h5-medium">{label}</p>
      <div className="para-body-l-medium">{value}</div>
    </div>
  );
}

function FormatLabel({ label, tooltip }) {
  return (
    <p className="flex items-center heading-h5-medium">
      {label}
      <CustomTooltip content={<div className="para-body-xs-medium">{tooltip}</div>} arrow={false} placement="top">
        <InfoOutlined className="amount-fee-item-icon" />
      </CustomTooltip>
    </p>
  );
}

const formatValue = (key, value) => {
  const lowerKey = key.toLowerCase();
  const formattedValue = getValue(value);

  switch (true) {
    case lowerKey.includes("paid on"):
      return value === "Not paid" ? (
        <p className="para-body-l-medium">{value}</p>
      ) : (
        <p className="para-body-l-medium">{convertDate(formattedValue)}</p>
      );

    case lowerKey.includes("payment status"):
      return getStatusChip(value);

    case lowerKey.includes("date"):
      return <p className="para-body-l-medium">{convertDate(formattedValue)}</p>;

    case lowerKey.includes("fee"):
      return <p className="para-body-l-medium">{`$ ${formattedValue.toLocaleString()}`}</p>;

    case lowerKey.includes("discount") || lowerKey.includes("prorated"):
      return <p className="para-body-l-medium">{`$ (${formattedValue.toLocaleString()})`}</p>;

    default:
      return <p className="para-body-l-medium">{formattedValue}</p>;
  }
};

const getLabel = (label) => {
  const lowerLabel = label.toLowerCase();

  if (lowerLabel.includes("total monthly fee"))
    return (
      <FormatLabel label="Total monthly fee" tooltip="Your regular monthly charge for your associate's services." />
    );

  if (lowerLabel.includes("total implementation fee"))
    return (
      <FormatLabel
        label="Total Implementation Fee"
        tooltip="This is a one-time charge to set up your account and activate services."
      />
    );

  if (lowerLabel.includes("total prorated adjustment"))
    return (
      <FormatLabel
        label="Total Prorated Adjustment"
        tooltip="Based on your start date with your associate, an amount is adjusted to ensure you only pay for the actual days of service received."
      />
    );

  return <p className="flex items-center heading-h5-medium">{label}</p>;
};

function PayrollDetails({ firstPayment }) {
  const { invoiceItems = [], chargeDetails } = firstPayment ?? {};
  const { chargeDate, amount, invoiceUrl } = chargeDetails ?? {};

  return (
    <div className="payroll-details-container">
      <div
        className="payroll-details-header"
        style={{
          background: "var(--color-primitives-primary-200)",
        }}
      >
        <div>
          <Chip className="pd-header-left-chip" label={convertDateToUTCMonthAndYear(chargeDate, 0)} />

          <p
            style={{
              color: "var(--color-primitives-black-black)",
            }}
            className="para-body-m-medium pt-075"
          >
            Total amount
          </p>
          <p
            style={{
              color: "var(--color-primitives-black-black)",
            }}
            className="heading-h1-semibold"
          >
            $ {currencyFormat(getValue(amount))}
          </p>
        </div>

        <Button
          variant="outlined"
          size="lg"
          id="changePhoto"
          onClick={() => window.open(invoiceUrl, "_blank")}
          style={{
            alignSelf: "center",
            height: "38px",
            color: "black",
            border: "1px solid white",
            background: "white",
          }}
        >
          Download Reciept
        </Button>
      </div>

      <div className="pd-payment-overview">
        <p className="heading-h4-semibold pb-1">Payment Overview</p>
        {invoiceItems?.map((item) => (
          <RenderPayrollItem key={item.key} label={getLabel(item.key)} value={formatValue(item.key, item.value)} />
        ))}
      </div>
    </div>
  );
}

export default PayrollDetails;
